import { authStore } from "@/core/modules/auth/store";
import { authStoreTypes } from "@/core/modules/auth/store/types";
import { koruDb } from "@/core/modules/database";
import { NotificationInterface } from "@/core/modules/notification/models/Notification.interface";
import { NotificationModelInterface } from "@/core/modules/notification/models/NotificationModel.interface";
import { UserInterface } from "@/core/modules/user/models/User.interface";

export const readUnreadNotifications = async (): Promise<NotificationInterface[]> => {
  try {
    const user: UserInterface = authStore.getter(authStoreTypes.getters.getUser);

    return await koruDb.getModule<NotificationModelInterface>("notifications").getUnreadNotifications(user.id);
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
};
