import { createStoreModule } from "@/core/modules/helpers";
import { EmptyStateInterface } from "@/core/modules/store/models/EmptyState.interface";
import { KoruStoreModuleInterface } from "@/core/modules/store/models/KoruStoreModule.interface";

import { actions } from "./actions";

export const notificationStore: KoruStoreModuleInterface<EmptyStateInterface> = createStoreModule("notificationState", undefined, undefined, actions);

export const useNotificationStore = (): KoruStoreModuleInterface<EmptyStateInterface> => {
  return notificationStore;
};
