import { createStoreModule } from "@/core/modules/helpers";
import { EmptyStateInterface } from "@/core/modules/store/models/EmptyState.interface";
import { KoruStoreModuleInterface } from "@/core/modules/store/models/KoruStoreModule.interface";

import { actions } from "./actions";

export const settingStore: KoruStoreModuleInterface<EmptyStateInterface> = createStoreModule("settingState", undefined, undefined, actions);

export const useSettingStore = (): KoruStoreModuleInterface<EmptyStateInterface> => {
  return settingStore;
};
