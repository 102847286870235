import { GetterTree } from "vuex";

import { authStore } from "@/core/modules/auth/store";
import { authStoreTypes } from "@/core/modules/auth/store/types";
import { KoruStateInterface } from "@/core/modules/store/models/KoruState.interface";
import { ThemeInterface } from "../models/Theme.interface";
import { ThemeStateInterface } from "../models/ThemeState.interface";
import { themeStoreTypes } from "./types";
import { UserInterface } from "@/core/modules/user/models/User.interface";

export const getters: GetterTree<ThemeStateInterface, KoruStateInterface> = {
  [themeStoreTypes.getters.getSelectedTheme]: (state: ThemeStateInterface): ThemeInterface => {
    const user: UserInterface = authStore.getter(authStoreTypes.getters.getUser);

    if (user != undefined) {
      for (const theme of state.themes) {
        if (theme.name == user.detail.theme) {
          return theme;
        }
      }
    }

    return state.themes[0];
  },
  [themeStoreTypes.getters.getThemes]: (state: ThemeStateInterface): ThemeInterface[] => {
    return state.themes;
  },
};
