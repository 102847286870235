import { createStoreModule } from "@/core/modules/helpers";
import { EmptyStateInterface } from "@/core/modules/store/models/EmptyState.interface";
import { KoruStoreModuleInterface } from "@/core/modules/store/models/KoruStoreModule.interface";

import { actions } from "./actions";

export const variableStore: KoruStoreModuleInterface<EmptyStateInterface> = createStoreModule("variableState", undefined, undefined, actions);

export const useVariableStore = (): KoruStoreModuleInterface<EmptyStateInterface> => {
  return variableStore;
};
