export const userStoreTypes = {
  actions: {
    // read documents
    getUsers: "GET_USERS",

    // read document
    getUser: "GET_USER",

    // create document
    createUser: "CREATE_USER",

    // update document
    updateUser: "UPDATE_USER",
    updateUserLastSeen: "UPDATE_USER_LAST_SEEN",
    toggleUser: "TOGGLE_USER",

    // delete document
    deleteUser: "DELETE_USER",

    // is email unique
    isEmailUnique: "IS_EMAIL_UNIQUE",
  },
};
