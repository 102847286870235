import { BlockInterface } from "@/features/modules/block/models/Block.interface";
import { BlockModelInterface } from "@/features/modules/block/models/BlockModel.interface";
import { koruDb } from "@/core/modules/database";

import { runBeforeDeleteFunction } from "@/core/modules/helpers";

export const deleteDocument = async (block: BlockInterface): Promise<void> => {
  try {
    await runBeforeDeleteFunction("functionsFeaturesBlockBeforeDelete", block.id);

    await koruDb.getModule<BlockModelInterface>("blocks").deleteDocument(block, true);
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
};
