import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

export interface DocumentInterface extends Record<string, unknown> {
  interfaceType: string;
  id: string;
  searchKeys: string[];
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
}

export const newDocument = {
  id: "new",
  searchKeys: [],
  createdAt: new Date(),
  createdBy: "",
  updatedAt: new Date(),
  updatedBy: "",
};

export function createDocument<T>(data: Record<string, unknown>): T {
  return Object.assign(data, newDocument) as T;
}

export function documentFromFirestore<T extends DocumentInterface>(id: string, data: Record<string, unknown>, document: Record<string, unknown>): T {
  return Object.assign(data, {
    id: id,
    searchKeys: document.searchKeys || [],
    createdAt: (document.createdAt as firebase.firestore.Timestamp).toDate(),
    createdBy: document.createdBy,
    updatedAt: (document.updatedAt as firebase.firestore.Timestamp).toDate(),
    updatedBy: document.updatedBy,
  }) as T;
}

export function documentToFirestore(data: Record<string, unknown>, document: DocumentInterface): Record<string, unknown> {
  return Object.assign(data, {
    searchKeys: document.searchKeys || [],
    createdAt: firebase.firestore.Timestamp.fromDate(document.createdAt),
    createdBy: document.createdBy,
    updatedAt: firebase.firestore.Timestamp.fromDate(document.updatedAt),
    updatedBy: document.updatedBy,
  });
}
