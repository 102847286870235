import { DocumentInterface, createDocument, documentFromFirestore, documentToFirestore } from "@/core/modules/database/models/Document.interface";
import {
  LinkedUserInterface,
  createNewLinkedUser,
  linkedUserFromFirestore,
  linkedUserToFirestore,
} from "@/core/modules/user/models/LinkedUser.interface";

export interface LogInterface extends DocumentInterface {
  interfaceType: string;
  user: LinkedUserInterface;
  firstName: string;
  message: string;
  type: string;
}

export const createNewLog = (): LogInterface => {
  return createDocument<LogInterface>({
    interfaceType: "Log",
    user: createNewLinkedUser(),
    message: undefined,
    type: undefined,
  });
};

export function logFromFirestore(data: Record<string, unknown>, id?: string): LogInterface {
  return documentFromFirestore<LogInterface>(
    id != undefined ? id : (data.id as string),
    {
      interfaceType: "Log",
      user: linkedUserFromFirestore(data.user as Record<string, unknown>),
      message: data.message as string,
      type: data.type as string,
    },
    data
  );
}

export function logToFirestore(log: LogInterface): Record<string, unknown> {
  return documentToFirestore(
    {
      user: linkedUserToFirestore(log.user),
      message: log.message,
      type: log.type,
    },
    log
  );
}
