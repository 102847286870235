import { ActionTree } from "vuex";

import { EmptyStateInterface } from "@/core/modules/store/models/EmptyState.interface";
import { KoruStateInterface } from "@/core/modules/store/models/KoruState.interface";
import { NotificationInterface } from "../models/Notification.interface";
import { notificationStoreTypes } from "./types";

import {
  createDocument,
  deleteDocument,
  markNotificationAsDeleted,
  markNotificationAsRead,
  readDocument,
  readDocuments,
  readNotificationsMatrix,
  readUnreadNotifications,
  updateDocument,
} from "./actions/index";

export const actions: ActionTree<EmptyStateInterface, KoruStateInterface> = {
  // read documents
  async [notificationStoreTypes.actions.getNotifications](): Promise<NotificationInterface[]> {
    return await readDocuments();
  },
  async [notificationStoreTypes.actions.getUnreadNotifications](): Promise<NotificationInterface[]> {
    return await readUnreadNotifications();
  },
  [notificationStoreTypes.actions.getNotificationsMatrix](context, notifications: NotificationInterface[]): Record<string, number> {
    return readNotificationsMatrix(notifications);
  },

  // read document
  async [notificationStoreTypes.actions.getNotification](context, notificationId: string): Promise<NotificationInterface> {
    return await readDocument(notificationId);
  },

  // create document
  async [notificationStoreTypes.actions.createNotification](context, notification: NotificationInterface): Promise<string> {
    return await createDocument(notification);
  },

  // update document
  async [notificationStoreTypes.actions.updateNotification](context, notification: NotificationInterface): Promise<void> {
    await updateDocument(notification);
  },

  // delete document
  async [notificationStoreTypes.actions.deleteNotification](context, notification: NotificationInterface): Promise<void> {
    await deleteDocument(notification);
  },

  // mark notification
  async [notificationStoreTypes.actions.markNotificationAsDeleted](context, notificationId: string): Promise<void> {
    await markNotificationAsDeleted(notificationId);
  },
  async [notificationStoreTypes.actions.markNotificationAsRead](context, notificationId: string): Promise<void> {
    await markNotificationAsRead(notificationId);
  },
};
