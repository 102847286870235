import { TemplateInterface } from "./Template.interface";

export interface LinkedTemplateInterface {
  id: string;
  name: string;
}

export const createNewLinkedTemplate = (): LinkedTemplateInterface => {
  return {
    id: "new",
    name: "",
  };
};

export const createLinkedTemplateFromTemplate = (template: TemplateInterface): LinkedTemplateInterface => {
  return {
    id: template.id,
    name: template.name,
  };
};

export function linkedTemplateFromFirestore(data: Record<string, unknown>): LinkedTemplateInterface {
  return {
    id: data.id as string,
    name: data.name as string,
  };
}

export function linkedTemplateToFirestore(data: LinkedTemplateInterface): Record<string, unknown> {
  return {
    id: data.id,
    name: data.name,
  };
}
