import { RoleInterface, roleFromFirestore, roleToFirestore } from "@/core/modules/role/models/Role.interface";
import { RoleModelInterface } from "../RoleModel.interface";

import { createDocumentHelper, deleteDocumentHelper, getDocumentHelper, getDocumentsHelper, updateDocumentHelper } from "@/core/modules/helpers";

import { searchDocuments } from "./methods";

export const roleModel: RoleModelInterface = {
  collectionName: "roles",
  documentFromFirestore: roleFromFirestore,
  documentToFirestore: roleToFirestore,

  async getDocuments(): Promise<RoleInterface[]> {
    return await getDocumentsHelper<RoleInterface>(this.collectionName, "name", "asc", 0, 0, this.documentFromFirestore, this.documentToFirestore);
  },
  async getDocument(documentId: string): Promise<RoleInterface> {
    return await getDocumentHelper<RoleInterface>(documentId, this.collectionName, this.documentFromFirestore, this.documentToFirestore);
  },
  async createDocument(document: RoleInterface, logAction: boolean): Promise<string> {
    return await createDocumentHelper<RoleInterface>(document, this.collectionName, this.documentFromFirestore, this.documentToFirestore, logAction);
  },
  async updateDocument(document: RoleInterface, logAction: boolean): Promise<void> {
    await updateDocumentHelper<RoleInterface>(document, this.collectionName, this.documentFromFirestore, this.documentToFirestore, logAction);
  },
  async deleteDocument(document: RoleInterface, logAction: boolean): Promise<void> {
    await deleteDocumentHelper(document, this.collectionName, logAction);
  },
  async searchDocuments(text: string): Promise<RoleInterface[]> {
    return searchDocuments(this, text);
  },
};
