export const en = {
  changelog: {
    addItem: "Add element",
    changelog: "Changelog",
    date: "Date",
    dateError: "Insert a valid date",
    empty: "No changelog to display",
    item: "Element",
    itemAdded: "Added",
    itemChanged: "Changed",
    itemFixed: "Fixed",
    itemRemoved: "Removed",
    newItem: "New element",
    public: "Public",
    release: "Release",
    releasedOn: "Released",
    tabGeneral: "General",
    version: "Version",
    versionError: "Insert the version",
  },
};
