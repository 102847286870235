import { koruAuth } from "@/core/modules/auth";
import { koruDb } from "@/core/modules/database";
import { UserInterface } from "@/core/modules/user/models/User.interface";
import { UserModelInterface } from "@/core/modules/user/models/UserModel.interface";

import { hasDocumentChanged, setDocumentFields } from "@/core/modules/helpers";
import { setFullNames, setSearchKeys } from "@/core/modules/user/helpers";
export const updateDocument = async (user: UserInterface): Promise<void> => {
  try {
    const oldUser: UserInterface = await koruDb.getModule<UserModelInterface>("users").getDocument(user.id);
    if (hasDocumentChanged(user, oldUser)) throw new Error("sync");

    if (oldUser.email != user.email) await koruAuth.updateUser(user);

    setFullNames(user);

    user.searchKeys = setSearchKeys(user);

    setDocumentFields(user.id, user);
    await koruDb.getModule<UserModelInterface>("users").updateDocument(user, true);
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
};
