import { koruConfig } from "@/core/modules/config";
import { RoleInterface } from "@/core/modules/role/models/Role.interface";

import { getObjectValueWithField } from "@/core/modules/helpers";

export const fromMatrixToValues = (payload: Record<string, unknown>): RoleInterface => {
  try {
    const role = payload.record as RoleInterface;
    const matrix = payload.matrix as Record<string, unknown>[];

    for (const right of koruConfig.authentication.rights) role[right] = [];

    for (const moduleValues of matrix) {
      const roleModule: string = moduleValues.roleModule as string;
      for (const right of koruConfig.authentication.rights) {
        if (moduleValues[right] == true) {
          if (!(getObjectValueWithField(role, right) as Array<string>).includes(roleModule)) {
            (getObjectValueWithField(role, right) as Array<string>).push(roleModule);
          }
        } else {
          const roleIndex = (getObjectValueWithField(role, right) as Array<string>).indexOf(roleModule, 0);
          if (roleIndex > -1) {
            (getObjectValueWithField(role, right) as Array<string>).splice(roleIndex, 1);
          }
        }
      }
    }

    return role;
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
};
