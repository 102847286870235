import { ActionTree } from "vuex";

import { AuthStateInterface } from "../models/AuthState.interface";
import { authStoreTypes } from "./types";
import { KoruStateInterface } from "@/core/modules/store/models/KoruState.interface";

import { changePassword, deleteDocument, forgotPassword, login, logout, recoverPassword, updateDocument } from "./actions/index";

export const actions: ActionTree<AuthStateInterface, KoruStateInterface> = {
  // authentication
  async [authStoreTypes.actions.login](context, { email, password }): Promise<void> {
    await login(context, email, password);
  },
  async [authStoreTypes.actions.logout](context): Promise<void> {
    await logout(context);
  },

  // password update
  async [authStoreTypes.actions.changePassword](context, { oldPassword, newPassword }): Promise<void> {
    await changePassword(context, oldPassword, newPassword);
  },
  async [authStoreTypes.actions.forgotPassword](context, email): Promise<void> {
    await forgotPassword(context, email);
  },
  async [authStoreTypes.actions.recoverPassword](context, { code, newPassword }): Promise<void> {
    await recoverPassword(context, code, newPassword);
  },

  // update document
  async [authStoreTypes.actions.changeEmail](context, { newEmail, password }) {
    await updateDocument(context, newEmail, password);
  },

  // delete document
  async [authStoreTypes.actions.deleteUser](context, userId: string): Promise<void> {
    await deleteDocument(userId);
  },
};
