import { registerDbModule } from "@/core/modules/helpers";

import { blockModel } from "@/features/modules/block/models/firestore/Block.model";
import { mapModel } from "@/features/modules/map/models/firestore/Map.model";
import { mapPointModel } from "@/features/modules/map-point/models/firestore/MapPoint.model";
import { pageModel } from "@/features/modules/page/models/firestore/Page.model";
import { settingModel } from "@/features/modules/setting/models/firestore/Setting.model";
import { templateModel } from "@/features/modules/template/models/firestore/Template.model";
import { variableModel } from "@/features/modules/variable/models/firestore/Variable.model";
import { webRenderModel } from "@/features/modules/web-render/models/firestore/WebRender.model";

export const registerFeaturesDbModules = () => {
  registerDbModule(blockModel);
  registerDbModule(mapModel);
  registerDbModule(mapPointModel);
  registerDbModule(pageModel);
  registerDbModule(settingModel);
  registerDbModule(templateModel);
  registerDbModule(variableModel);
  registerDbModule(webRenderModel);
};
