import { RoleInterface } from "./Role.interface";

export interface LinkedRoleInterface {
  id: string;
  name: string | undefined;
  readAll: string[];
  readOwn: string[];
  create: string[];
  updateAll: string[];
  updateOwn: string[];
  deleteAll: string[];
  deleteOwn: string[];
}

export const createNewLinkedRole = (): LinkedRoleInterface => {
  return {
    id: "new",
    name: undefined,
    readAll: [],
    readOwn: [],
    create: [],
    updateAll: [],
    updateOwn: [],
    deleteAll: [],
    deleteOwn: [],
  };
};

export const createLinkedRoleFromRole = (role: RoleInterface): LinkedRoleInterface => {
  return {
    id: role.id,
    name: role.name,
    readAll: role.readAll,
    readOwn: role.readOwn,
    create: role.create,
    updateAll: role.updateAll,
    updateOwn: role.updateOwn,
    deleteAll: role.deleteAll,
    deleteOwn: role.deleteOwn,
  };
};

export function linkedRoleFromFirestore(data: Record<string, unknown>): LinkedRoleInterface {
  return {
    id: data.id as string,
    name: data.name as string,
    readAll: data.readAll as string[],
    readOwn: data.readOwn as string[],
    create: data.create as string[],
    updateAll: data.updateAll as string[],
    updateOwn: data.updateOwn as string[],
    deleteAll: data.deleteAll as string[],
    deleteOwn: data.deleteOwn as string[],
  };
}

export function linkedRoleToFirestore(data: LinkedRoleInterface): Record<string, unknown> {
  return {
    id: data.id,
    name: data.name || null,
    readAll: data.readAll,
    readOwn: data.readOwn,
    create: data.create,
    updateAll: data.updateAll,
    updateOwn: data.updateOwn,
    deleteAll: data.deleteAll,
    deleteOwn: data.deleteOwn,
  };
}
