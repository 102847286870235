import { createStoreModule } from "@/core/modules/helpers";
import { EmptyStateInterface } from "@/core/modules/store/models/EmptyState.interface";
import { KoruStoreModuleInterface } from "@/core/modules/store/models/KoruStoreModule.interface";

import { actions } from "./actions";

export const webRenderStore: KoruStoreModuleInterface<EmptyStateInterface> = createStoreModule("webRenderState", undefined, undefined, actions);

export const useWebRenderStore = (): KoruStoreModuleInterface<EmptyStateInterface> => {
  return webRenderStore;
};
