export const notificationStoreTypes = {
  actions: {
    // read documents
    getNotifications: "GET_NOTIFICATIONS",
    getNotificationsMatrix: "GET_NOTIFICATIONS_MATRIX",
    getUnreadNotifications: "GET_UNREAD_NOTIFICATIONS",

    // read document
    getNotification: "GET_NOTIFICATION",

    // create document
    createNotification: "CREATE_NOTIFICATION",

    // update document
    updateNotification: "UPDATE_NOTIFICATION",

    // delete document
    deleteNotification: "DELETE_NOTIFICATION",

    // mark notification
    markNotificationAsRead: "MARK_NOTIFICATION_AS_READ",
    markNotificationAsDeleted: "MARK_NOTIFICATION_AS_DELETED",
  },
};
