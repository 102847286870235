import { DocumentInterface } from "@/core/modules/database/models/Document.interface";
import { koruSearch } from "@/core/modules/search/module";
import { SearchResultInterface } from "@/core/modules/search/models/SearchResult.interface";
import { SearchResultItemInterface } from "@/core/modules/search/models/SearchResultItem.interface";
import { userCanRead } from "@/core/modules/helpers";

export const performSearch = async (payload: Record<string, unknown>): Promise<SearchResultInterface[]> => {
  try {
    const searchText: string = payload.searchText as string;
    const d: (date: Date, format: string) => string = payload.d as (date: Date, format: string) => string;
    const n: (number: number, format: string) => string = payload.n as (number: number, format: string) => string;
    const t: (text: string) => string = payload.t as (text: string) => string;

    if (searchText == undefined || d == undefined || n == undefined || t == undefined) throw new Error("invalid parameters");

    const searchResults: SearchResultInterface[] = [];

    for (const searchModule of koruSearch.modules) {
      if (searchModule.roleModule != undefined && !userCanRead(searchModule.roleModule)) continue;

      const results: DocumentInterface[] = await searchModule.search(searchText);
      if (results.length > 0) {
        const items: SearchResultItemInterface[] = [];
        for (const result of results) {
          items.push({
            title: searchModule.format(result, d, n, t),
            link: searchModule.getLink(result),
          });
        }
        searchResults.push({
          title: searchModule.title,
          icon: searchModule.icon,
          items,
        });
      }
    }

    return searchResults;
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
};
