import { DatabaseModuleInterface } from "@/core/modules/database/models/DatabaseModule.interface";
import { koruDb } from "@/core/modules/database";
import { RoleInterface } from "@/core/modules/role/models/Role.interface";

import { hasDocumentChanged, setDocumentFields } from "@/core/modules/helpers";
import { setSearchKeys } from "@/core/modules/role/helpers";

export const updateDocument = async (role: RoleInterface): Promise<void> => {
  try {
    const oldRole: RoleInterface = await koruDb.getModule<DatabaseModuleInterface<RoleInterface>>("roles").getDocument(role.id);
    if (hasDocumentChanged(role, oldRole)) throw new Error("sync");

    role.searchKeys = setSearchKeys(role);

    setDocumentFields(role.id, role);
    await koruDb.getModule<DatabaseModuleInterface<RoleInterface>>("roles").updateDocument(role, true);
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
};
