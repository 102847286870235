import { ActionContext } from "vuex";

import { AuthStateInterface } from "@/core/modules/auth/models/AuthState.interface";
import { authStoreTypes } from "../types";
import { koruAuth } from "@/core/modules/auth";
import { KoruStateInterface } from "@/core/modules/store/models/KoruState.interface";
import { UserInterface } from "@/core/modules/user/models/User.interface";

export const login = async (context: ActionContext<AuthStateInterface, KoruStateInterface>, email: string, password: string): Promise<void> => {
  context.commit(authStoreTypes.mutations.loadingStart);

  try {
    const user: UserInterface = await koruAuth.login(email, password);
    context.commit(authStoreTypes.mutations.loginSuccess, user);
  } catch (error: unknown) {
    context.commit(authStoreTypes.mutations.loginError);
    throw new Error((error as Error).message);
  }
};
