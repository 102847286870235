import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { firebaseFirestore } from "@/core/plugins/firebase";

import { CollectionInterface } from "@/core/modules/config/models/Collection.interface";
import { koruBatch } from "@/core/modules/batch";
import { WorkbenchModuleInterface } from "../models/WorkbenchModule.interface";

export const deleteData: WorkbenchModuleInterface = {
  name: "deleteData",
  label: "workbench.deleteData",
  action: async (selectedCollections: CollectionInterface[]): Promise<void> => {
    return await performAction(selectedCollections);
  },
};

async function performAction(selectedCollections: CollectionInterface[]): Promise<void> {
  try {
    for (const collection of selectedCollections) {
      const snapshot: firebase.firestore.QuerySnapshot = await firebaseFirestore.collection(collection.name).get();
      if (!snapshot.empty) {
        for (const doc of snapshot.docs) koruBatch.delete(doc.ref);
      }
    }
    await koruBatch.commit();
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
}
