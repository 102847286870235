export const changelogStoreTypes = {
  actions: {
    // read documents
    getPublicReleases: "GET_PUBLIC_RELEASES",
    getReleases: "GET_RELEASES",

    // read document
    getRelease: "GET_RELEASE",

    // create document
    createRelease: "CREATE_RELEASE",

    // update document
    updateRelease: "UPDATE_RELEASE",

    // delete document
    deleteRelease: "DELETE_RELEASE",

    // send notification
    sendNotification: "SEND_NOTIFICATION",
  },
};
