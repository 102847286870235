import { koruDb } from "@/core/modules/database";
import { UserInterface } from "@/core/modules/user/models/User.interface";
import { UserModelInterface } from "@/core/modules/user/models/UserModel.interface";

export const readDocument = async (documentId: string): Promise<UserInterface> => {
  try {
    return await koruDb.getModule<UserModelInterface>("users").getDocument(documentId);
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
};
