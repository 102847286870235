import { Ref } from "vue";

import { UserInterface } from "../models/User.interface";
import { userStore } from "../store";
import { userStoreTypes } from "../store/types";

/* set full names */

export const setFullNames = (user: UserInterface): void => {
  if (user.lastName != undefined && user.firstName != undefined) {
    user.fullName = `${user.lastName} ${user.firstName}`;
    user.fullNameReversed = `${user.firstName} ${user.lastName}`;
  } else {
    user.fullName = undefined;
    user.fullNameReversed = undefined;
  }
};

/* set search keys */

export const setSearchKeys = (user: UserInterface): string[] => {
  const searchKeys: string[] = [];
  if (user.lastName != undefined) searchKeys.push(user.lastName.toLowerCase());
  if (user.firstName != undefined) searchKeys.push(user.firstName.toLowerCase());
  if (user.email != undefined) searchKeys.push(user.email.toLowerCase());

  return searchKeys;
};

/* validation */

export const uniqueEmailValidator = (oldEmail: Ref<string | undefined>) => {
  return async (value: string, record: UserInterface) => {
    try {
      if (record == undefined) return false;
      return (await userStore.action(userStoreTypes.actions.isEmailUnique, {
        email: value,
        oldEmail: record.id == "new" ? undefined : oldEmail.value,
      })) as boolean;
    } catch (error: unknown) {
      console.log(error);
      return false;
    }
  };
};
