export const en = {
  log: {
    action: "Action",
    date: "Date",
    log: "Action log",
    type: "Type",
    types: {
      error: "Error",
      info: "Action",
      warning: "Warning",
    },
    user: "User",
  },
};
