import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputNumber = _resolveComponent("InputNumber")!

  return (_openBlock(), _createBlock(_component_InputNumber, {
    class: _normalizeClass(_ctx.classesRef),
    locale: _ctx.locale.name,
    minFractionDigits: _ctx.digits,
    maxFractionDigits: _ctx.digits,
    suffix: " %",
    "v-model": _ctx.model,
    onClick: _ctx.inputSelectAll,
    onFocus: _ctx.inputSelectAll,
    onInput: _ctx.updateModel
  }, null, 8, ["class", "locale", "minFractionDigits", "maxFractionDigits", "v-model", "onClick", "onFocus", "onInput"]))
}