import { DatabaseModuleInterface } from "@/core/modules/database/models/DatabaseModule.interface";
import { DocumentInterface } from "@/core/modules/database/models/Document.interface";
import { koruBatch } from "@/core/modules/batch";
import { koruDb } from "@/core/modules/database";
import { SearchModuleInterface } from "@/core/modules/search/models/SearchModule.interface";
import { RoleInterface } from "../models/Role.interface";
import { roleStore } from "../store";
import { roleStoreTypes } from "../store/types";

import { getCollectionReference } from "@/core/modules/helpers";
import { setSearchKeys } from "../helpers";

export const roleSearch: SearchModuleInterface = {
  collectionName: "roles",
  format: (doc: DocumentInterface) => doc.name as string,
  getLink: (doc: DocumentInterface) => `/roles/${doc.id}/edit`,
  icon: "tags",
  localeModule: "role",
  roleModule: "role",
  search: (text: string) => koruDb.getModule<DatabaseModuleInterface<RoleInterface>>("roles").searchDocuments(text),
  setSearchKeys: processSearchKeys,
  title: "role.role",
};

async function processSearchKeys(): Promise<void> {
  try {
    const rolees: RoleInterface[] = (await roleStore.action(roleStoreTypes.actions.getRoles)) as RoleInterface[];
    for (const role of rolees) {
      const searchKeys: string[] = setSearchKeys(role);
      koruBatch.update(getCollectionReference("roles").doc(role.id), { searchKeys: searchKeys });
    }

    await koruBatch.commit();
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
}
