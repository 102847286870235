import { DummyModuleInterface } from "@/core/modules/dummy/models/DummyModule.interface";
import { koruBatch } from "@/core/modules/batch";
import { RoleInterface, createNewRole, roleToFirestore } from "../models/Role.interface";

import { getCollectionReference } from "@/core/modules/helpers";

export const roleDummy: DummyModuleInterface = {
  collectionName: "roles",
  action: async (): Promise<void> => {
    const role: RoleInterface = createNewRole();

    const rights: string[] = ["backup", "changelog", "dummy", "exchange", "log", "role", "user", "workbench"];

    role.name = "Master";
    role.readAll = rights;
    role.readOwn = [];
    role.create = rights;
    role.updateAll = rights;
    role.updateOwn = [];
    role.deleteAll = rights;
    role.deleteOwn = [];

    const timestamp = new Date();
    role.createdAt = timestamp;
    role.createdBy = "install";
    role.updatedAt = timestamp;
    role.updatedBy = "install";

    koruBatch.set(getCollectionReference("roles").doc("95Clp88EfEyX5fY0DdNS"), roleToFirestore(role));
  },
  additionalAction: async (): Promise<void> => Promise.resolve(),
};
