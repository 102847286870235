import { BlockInterface } from "@/features/modules/block/models/Block.interface";
import { BlockModelInterface } from "@/features/modules/block/models/BlockModel.interface";
import { koruDb } from "@/core/modules/database";

export const readDocuments = async (): Promise<BlockInterface[]> => {
  try {
    return await koruDb.getModule<BlockModelInterface>("blocks").getDocuments();
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
};
