import { ActionTree } from "vuex";

import { EmptyStateInterface } from "@/core/modules/store/models/EmptyState.interface";
import { BlockInterface } from "../models/Block.interface";
import { blockStoreTypes } from "./types";
import { KoruStateInterface } from "@/core/modules/store/models/KoruState.interface";

import { createDocument, deleteDocument, readDocument, readDocuments, reorderBlocks, updateDocument } from "./actions/index";

export const actions: ActionTree<EmptyStateInterface, KoruStateInterface> = {
  // read documents
  async [blockStoreTypes.actions.getBlocks](): Promise<BlockInterface[]> {
    return await readDocuments();
  },

  // read document
  async [blockStoreTypes.actions.getBlock](context, blockId: string): Promise<BlockInterface> {
    return await readDocument(blockId);
  },

  // create block
  async [blockStoreTypes.actions.createBlock](context, block: BlockInterface): Promise<string> {
    return await createDocument(block);
  },

  // update block
  async [blockStoreTypes.actions.updateBlock](context, block: BlockInterface): Promise<void> {
    await updateDocument(block);
  },

  // delete block
  async [blockStoreTypes.actions.deleteBlock](context, block: BlockInterface): Promise<void> {
    await deleteDocument(block);
  },

  // reorder blocks
  async [blockStoreTypes.actions.reorderBlocks](context, blocks: BlockInterface[]): Promise<void> {
    await reorderBlocks(blocks);
  },
};
