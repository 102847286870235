import { DatabaseModuleInterface } from "@/core/modules/database/models/DatabaseModule.interface";
import { koruDb } from "@/core/modules/database";
import { RoleInterface } from "@/core/modules/role/models/Role.interface";

import { setDocumentFields } from "@/core/modules/helpers";
import { setSearchKeys } from "@/core/modules/role/helpers";

export const createDocument = async (role: RoleInterface): Promise<string> => {
  try {
    role.searchKeys = setSearchKeys(role);

    setDocumentFields(role.id, role);
    return await koruDb.getModule<DatabaseModuleInterface<RoleInterface>>("roles").createDocument(role, true);
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
};
