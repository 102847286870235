import { BlockInterface } from "@/features/modules/block/models/Block.interface";
import { BlockModelInterface } from "@/features/modules/block/models/BlockModel.interface";
import { koruDb } from "@/core/modules/database";

export const reorderBlocks = async (blocks: BlockInterface[]): Promise<void> => {
  try {
    await koruDb.getModule<BlockModelInterface>("blocks").reorderBlocks(blocks);
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
};
