export const blockStoreTypes = {
  actions: {
    // read documents
    getBlocks: "GET_BLOCKS",

    // read document
    getBlock: "GET_BLOCK",

    // create document
    createBlock: "CREATE_BLOCK",

    // update document
    updateBlock: "UPDATE_BLOCK",

    // delete document
    deleteBlock: "DELETE_BLOCK",

    // reorder exam types
    reorderBlocks: "REORDER_BLOCKS",
  },
};
