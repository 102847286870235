import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import { NotificationInterface } from "@/core/modules/notification/models/Notification.interface";
import { NotificationModelInterface } from "@/core/modules/notification/models/NotificationModel.interface";

import { createFirestoreConverter, getCollectionReference } from "@/core/modules/helpers";

export const getUnreadNotifications = async (notificationModel: NotificationModelInterface, userId: string): Promise<NotificationInterface[]> => {
  try {
    const snapshot: firebase.firestore.QuerySnapshot<NotificationInterface> = await getCollectionReference(notificationModel.collectionName)
      .withConverter(createFirestoreConverter(notificationModel.documentFromFirestore, notificationModel.documentToFirestore))
      .where("unread", "array-contains", userId)
      .orderBy("createdAt", "desc")
      .get();

    if (snapshot == undefined || snapshot.empty) return [];

    return snapshot.docs.map((doc) => doc.data());
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
};
