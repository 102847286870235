import { BlockInterface } from "@/features/modules/block/models/Block.interface";
import { BlockModelInterface } from "@/features/modules/block/models/BlockModel.interface";
import { koruDb } from "@/core/modules/database";

import { setDocumentFields } from "@/core/modules/helpers";

export const createDocument = async (block: BlockInterface): Promise<string> => {
  try {
    const blocksCount: number = await koruDb.getModule<BlockModelInterface>("blocks").countBlocks();
    block.order = blocksCount + 1;

    setDocumentFields(block.id, block);
    return await koruDb.getModule<BlockModelInterface>("blocks").createDocument(block, true);
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
};
