export const authStoreTypes = {
  getters: {
    isLoading: "IS_LOADING",
    isLoggedIn: "IS_LOGGED_IN",
    getUser: "GET_USER",
  },
  actions: {
    // authentication
    login: "LOGIN",
    logout: "LOGOUT",

    // password update
    changePassword: "CHANGE_PASSWORD",
    forgotPassword: "FORGOT_PASSWORD",
    recoverPassword: "RECOVER_PASSWORD",

    // update document
    changeEmail: "CHANGE_EMAIL",

    // delete document
    deleteUser: "DELETE_USER",
  },
  mutations: {
    loadingStart: "LOADING_START",
    loadingStop: "LOADING_STOP",
    loginSuccess: "LOGIN_SUCCESS",
    loginError: "LOGIN_ERROR",
    logoutSuccess: "LOGOUT_SUCCESS",
  },
};
