import { RouteRecordRaw } from "vue-router";

export const dummyRoutes: Array<RouteRecordRaw> = [
  {
    path: "/dummy",
    name: "DummyView",
    component: () => import("../views/DummyView.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "dummy",
      requiredRights: ["create", "updateAll", "updateOwn"],
    },
  },
];
