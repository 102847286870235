import { ActionTree } from "vuex";

import { BackupInterface } from "../models/Backup.interface";
import { BackupStateInterface } from "../models/BackupState.interface";
import { backupStoreTypes } from "./types";
import { KoruStateInterface } from "@/core/modules/store/models/KoruState.interface";

import { createDocument, deleteDocument, readDocuments, restoreBackup } from "./actions/index";

export const actions: ActionTree<BackupStateInterface, KoruStateInterface> = {
  // read documents
  async [backupStoreTypes.actions.getBackups](): Promise<BackupInterface[]> {
    return await readDocuments();
  },

  // create document
  async [backupStoreTypes.actions.createBackup](): Promise<void> {
    await createDocument();
  },

  // delete document
  async [backupStoreTypes.actions.deleteBackup](context, backup: BackupInterface): Promise<void> {
    await deleteDocument(backup);
  },

  // restore backup
  async [backupStoreTypes.actions.restoreBackup](context): Promise<void> {
    await restoreBackup(context);
  },
};
