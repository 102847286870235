import { UserInterface } from "./User.interface";

export interface LinkedUserInterface {
  id: string;
  firstName: string | undefined;
  lastName: string | undefined;
  fullName: string | undefined;
  fullNameReversed: string | undefined;
  email: string | undefined;
}

export const createNewLinkedUser = (): LinkedUserInterface => {
  return {
    id: "new",
    firstName: undefined,
    lastName: undefined,
    fullName: undefined,
    fullNameReversed: undefined,
    email: undefined,
  };
};

export const createLinkedUserFromUser = (user: UserInterface): LinkedUserInterface => {
  return {
    id: user.id,
    firstName: user.firstName,
    lastName: user.lastName,
    fullName: user.fullName,
    fullNameReversed: user.fullNameReversed,
    email: user.email,
  };
};

export function linkedUserFromFirestore(data: Record<string, unknown>): LinkedUserInterface {
  return {
    id: data.id as string,
    firstName: data.firstName as string,
    lastName: data.lastName as string,
    fullName: data.fullName as string,
    fullNameReversed: data.fullNameReversed as string,
    email: data.email as string,
  };
}

export function linkedUserToFirestore(data: LinkedUserInterface): Record<string, unknown> {
  return {
    id: data.id,
    firstName: data.firstName || null,
    lastName: data.lastName || null,
    fullName: data.fullName || null,
    fullNameReversed: data.fullNameReversed || null,
    email: data.email || null,
  };
}
