import { ActionTree } from "vuex";

import { EmptyStateInterface } from "@/core/modules/store/models/EmptyState.interface";
import { KoruStateInterface } from "@/core/modules/store/models/KoruState.interface";
import { installerStoreTypes } from "./types";

import { checkInstallStatus, install } from "./actions/index";

export const actions: ActionTree<EmptyStateInterface, KoruStateInterface> = {
  async [installerStoreTypes.actions.checkInstallStatus](): Promise<string> {
    return checkInstallStatus();
  },
  async [installerStoreTypes.actions.install](): Promise<void> {
    return install();
  },
};
