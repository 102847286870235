import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "profile-menu fade-in-up" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.locales, (locale) => {
      return (_openBlock(), _createElementBlock("li", {
        key: locale.name
      }, [
        _createElementVNode("a", {
          onClick: ($event: any) => (_ctx.selectLocale(locale))
        }, [
          _createElementVNode("span", {
            class: _normalizeClass('flag-icon flag-icon-' + locale.flag)
          }, null, 2),
          _createElementVNode("span", {
            class: _normalizeClass({ 'text-bold': _ctx.selectedLocale == locale })
          }, _toDisplayString(locale.title), 3)
        ], 8, _hoisted_2)
      ]))
    }), 128))
  ]))
}