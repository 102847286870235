import { ChangelogModelInterface } from "@/core/modules/changelog/models/ChangelogModel.interface";
import { koruDb } from "@/core/modules/database";
import { ReleaseInterface } from "@/core/modules/changelog/models/Release.interface";

export const deleteDocument = async (release: ReleaseInterface): Promise<void> => {
  try {
    await koruDb.getModule<ChangelogModelInterface>("changelog").deleteDocument(release, false);
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
};
