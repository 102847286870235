import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "koru-loading" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: `/assets/layout/images/${_ctx.koruConfig.app.loading.logo}-logo-white.svg`,
      alt: "logo"
    }, null, 8, _hoisted_2)
  ]))
}