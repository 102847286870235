import { authStore } from "@/core/modules/auth/store";
import { authStoreTypes } from "@/core/modules/auth/store/types";
import { koruDb } from "@/core/modules/database";
import { NotificationModelInterface } from "@/core/modules/notification/models/NotificationModel.interface";
import { UserInterface } from "@/core/modules/user/models/User.interface";

export const markNotificationAsRead = async (documentId: string): Promise<void> => {
  try {
    const user: UserInterface = authStore.getter(authStoreTypes.getters.getUser);

    await koruDb.getModule<NotificationModelInterface>("notifications").markNotificationAsRead(documentId, user.id);
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
};
