import { BlockInterface, blockFromFirestore, blockToFirestore } from "../Block.interface";
import { BlockModelInterface } from "../BlockModel.interface";

import { createDocumentHelper, deleteDocumentHelper, getDocumentHelper, getDocumentsHelper, updateDocumentHelper } from "@/core/modules/helpers";

import { countBlocks, reorderBlocks } from "./methods";

export const blockModel: BlockModelInterface = {
  collectionName: "blocks",
  documentFromFirestore: blockFromFirestore,
  documentToFirestore: blockToFirestore,

  async getDocuments(): Promise<BlockInterface[]> {
    return await getDocumentsHelper<BlockInterface>(this.collectionName, "order", "asc", 0, 0, this.documentFromFirestore, this.documentToFirestore);
  },
  async getDocument(documentId: string): Promise<BlockInterface> {
    return await getDocumentHelper<BlockInterface>(documentId, this.collectionName, this.documentFromFirestore, this.documentToFirestore);
  },
  async createDocument(document: BlockInterface, logAction: boolean): Promise<string> {
    return await createDocumentHelper<BlockInterface>(document, this.collectionName, this.documentFromFirestore, this.documentToFirestore, logAction);
  },
  async updateDocument(document: BlockInterface, logAction: boolean): Promise<void> {
    await updateDocumentHelper<BlockInterface>(document, this.collectionName, this.documentFromFirestore, this.documentToFirestore, logAction);
  },
  async deleteDocument(document: BlockInterface, logAction: boolean): Promise<void> {
    await deleteDocumentHelper(document, this.collectionName, logAction);
  },
  async searchDocuments(): Promise<BlockInterface[]> {
    return Promise.resolve([]);
  },

  /* custom methods */

  async countBlocks(): Promise<number> {
    return await countBlocks(this);
  },
  async reorderBlocks(blocks: BlockInterface[]): Promise<void> {
    await reorderBlocks(blocks);
  },
};
