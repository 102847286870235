import { MutationTree } from "vuex";

import { DummyStateInterface } from "../models/DummyState.interface";
import { dummyStoreTypes } from "./types";

export const mutations: MutationTree<DummyStateInterface> = {
  [dummyStoreTypes.mutations.resetUploadedData]: (state: DummyStateInterface): void => {
    state.uploadedData = undefined;
  },
  [dummyStoreTypes.mutations.setUploadedData]: (state: DummyStateInterface, data: string): void => {
    state.uploadedData = data;
  },
};
