import { BlockInterface } from "./Block.interface";

export interface LinkedBlockInterface {
  id: string;
  name: string;
  code: Record<string, string>;
}

export const createNewLinkedBlock = (): LinkedBlockInterface => {
  return {
    id: "new",
    name: "",
    code: {},
  };
};

export const createLinkedBlockFromBlock = (block: BlockInterface): LinkedBlockInterface => {
  return {
    id: block.id,
    name: block.name,
    code: block.code,
  };
};

export function linkedBlockFromFirestore(data: Record<string, unknown>): LinkedBlockInterface {
  return {
    id: data.id as string,
    name: data.name as string,
    code: data.code as Record<string, string>,
  };
}

export function linkedBlockToFirestore(data: LinkedBlockInterface): Record<string, unknown> {
  return {
    id: data.id,
    name: data.name,
    code: data.code,
  };
}
