import { RouteRecordRaw } from "vue-router";

export const roleRoutes: Array<RouteRecordRaw> = [
  {
    path: "/roles",
    name: "RoleList",
    component: () => import("../views/RoleList.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "role",
      requiredRights: ["readAll", "readOwn"],
    },
  },
  {
    path: "/roles/:id/edit",
    name: "RoleEdit",
    component: () => import("../views/RoleEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "role",
      requiredRights: ["create", "updateAll", "updateOwn"],
    },
  },
];
