export const koruStoreTypes = {
  getters: {
    isEditing: "IS_EDITING",
    isLoading: "IS_LOADING",
    getColorScheme: "GET_COLOR_SCHEME",
    getPreviousRoute: "GET_PREVIOUS_ROUTE",
    showEditingDialog: "SHOW_EDITING_DIALOG",
  },
  actions: {
    navigateTo: "NAVIGATE_TO",
    navigateUndercoverTo: "NAVIGATE_UNDERCOVER_TO",
    navigateWithConfirm: "NAVIGATE_WITH_CONFIRM",
    navigateBack: "NAVIGATE_BACK",
  },
  mutations: {
    closeEditingDialog: "CLOSE_EDITING_DIALOG",
    editingStart: "EDITING_START",
    editingStop: "EDITING_STOP",
    loadingStart: "LOADING_START",
    loadingStop: "LOADING_STOP",
    openEditingDialog: "OPEN_EDITING_DIALOG",
    setPreviousRoute: "SET_PREVIOUS_ROUTE",
  },
};
