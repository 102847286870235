import { UserInterface, userFromFirestore, userToFirestore } from "@/core/modules/user/models/User.interface";
import { UserModelInterface } from "../UserModel.interface";

import { createDocumentHelper, deleteDocumentHelper, getDocumentHelper, getDocumentsHelper, updateDocumentHelper } from "@/core/modules/helpers";

import { getUsersIdsByModuleAndRight, isUserEmailUnique, searchDocuments } from "./methods";

export const userModel: UserModelInterface = {
  collectionName: "users",
  documentFromFirestore: userFromFirestore,
  documentToFirestore: userToFirestore,

  async getDocuments(): Promise<UserInterface[]> {
    return await getDocumentsHelper<UserInterface>(
      this.collectionName,
      "fullName",
      "asc",
      0,
      0,
      this.documentFromFirestore,
      this.documentToFirestore
    );
  },
  async getDocument(documentId: string): Promise<UserInterface> {
    return await getDocumentHelper<UserInterface>(documentId, this.collectionName, this.documentFromFirestore, this.documentToFirestore);
  },
  async createDocument(document: UserInterface, logAction: boolean): Promise<string> {
    return await createDocumentHelper<UserInterface>(document, this.collectionName, this.documentFromFirestore, this.documentToFirestore, logAction);
  },
  async updateDocument(document: UserInterface, logAction: boolean): Promise<void> {
    await updateDocumentHelper<UserInterface>(document, this.collectionName, this.documentFromFirestore, this.documentToFirestore, logAction);
  },
  async deleteDocument(document: UserInterface, logAction: boolean): Promise<void> {
    await deleteDocumentHelper(document, this.collectionName, logAction);
  },
  async searchDocuments(text: string): Promise<UserInterface[]> {
    return searchDocuments(this, text);
  },

  /* custom methods */

  async getUsersIdsByModuleAndRight(module: string, right: string): Promise<string[]> {
    return getUsersIdsByModuleAndRight(this, module, right);
  },
  async isUserEmailUnique(email: string, oldEmail: string): Promise<boolean> {
    return isUserEmailUnique(this, email, oldEmail);
  },
};
