import { createApp, h } from "vue";

import { initKoruLocale } from "@/core/modules/locale";
import { koruAuth } from "@/core/modules/auth";
import { koruConfig } from "./core/modules/config";
import { KoruLayout } from "@/core/modules/layout";
import { koruRouter } from "@/core/modules/router";
import { koruStore } from "@/core/modules/store";

import {
  registerCoreDbModules,
  registerCoreDummyModules,
  registerCoreLocales,
  registerCoreRoutes,
  registerCoreSearchModules,
  registerCoreStoreModules,
  registerCoreWorkbenchModules,
  registerExchangeModules,
  registerFeaturesDbModules,
  registerFeaturesDummyModules,
  registerFeaturesLocales,
  registerFeaturesRoutes,
  registerFeaturesSearchModules,
  registerFeaturesStoreModules,
  registerFeaturesWorkbenchModules,
} from "@/boot";

import { initSentry, resetLocalStorageIfNeeded } from "@/core/modules/helpers";

import Base from "@/core/layout/Base.vue";
import Bedrock from "@/core/layout/Bedrock.vue";

/////////////////////////////////////////////////////////////
//                       create app                        //
/////////////////////////////////////////////////////////////

export const koruApp = createApp({
  computed: {
    ViewComponent() {
      const coreBaseRoutes = ["AccessDenied", "Error", "Forgot", "Installer", "Login", "NotFound", "Recover"];

      const baseRoutes = coreBaseRoutes.concat(koruConfig.app.baseRoutes);

      if (this.$route.name == undefined) return Base;

      if (baseRoutes.includes(this.$route.name)) return Base;

      return Bedrock;
    },
  },
  render() {
    return h(this.ViewComponent);
  },
});

/////////////////////////////////////////////////////////////
//                     register routes                     //
/////////////////////////////////////////////////////////////

registerCoreRoutes();

registerFeaturesRoutes();

/////////////////////////////////////////////////////////////
//                register database modules                //
/////////////////////////////////////////////////////////////

registerCoreDbModules();

registerFeaturesDbModules();

/////////////////////////////////////////////////////////////
//                 register store modules                  //
/////////////////////////////////////////////////////////////

registerCoreStoreModules();

registerFeaturesStoreModules();

/////////////////////////////////////////////////////////////
//                    register locales                     //
/////////////////////////////////////////////////////////////

registerCoreLocales();

registerFeaturesLocales();

/////////////////////////////////////////////////////////////
//               register dummy data modules               //
/////////////////////////////////////////////////////////////

registerCoreDummyModules();

registerFeaturesDummyModules();

/////////////////////////////////////////////////////////////
//                 register search modules                 //
/////////////////////////////////////////////////////////////

registerCoreSearchModules();

registerFeaturesSearchModules();

/////////////////////////////////////////////////////////////
//               register workbench modules                //
/////////////////////////////////////////////////////////////

registerCoreWorkbenchModules();

registerFeaturesWorkbenchModules();

/////////////////////////////////////////////////////////////
//                register exchange modules                //
/////////////////////////////////////////////////////////////

registerExchangeModules();

/////////////////////////////////////////////////////////////
//                          boot                           //
/////////////////////////////////////////////////////////////

async function boot() {
  try {
    await koruAuth.init();

    if (koruApp.config.globalProperties.mounted !== true) {
      KoruLayout.init(koruApp);

      initSentry();

      koruApp.use(initKoruLocale());
      koruApp.use(koruRouter);
      koruApp.use(koruStore);

      koruApp.mount("#app");
      koruApp.config.globalProperties.mounted = true;

      resetLocalStorageIfNeeded();
    }
  } catch (error: unknown) {
    console.log("Unable to boot KORU");
    console.log(error);
  }
}

boot();
