import { ChangelogModelInterface } from "@/core/modules/changelog/models/ChangelogModel.interface";
import { koruDb } from "@/core/modules/database";
import { ReleaseInterface } from "@/core/modules/changelog/models/Release.interface";

export const readPublicReleases = async (): Promise<ReleaseInterface[]> => {
  try {
    return await koruDb.getModule<ChangelogModelInterface>("changelog").getPublicReleases();
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
};
