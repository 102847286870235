import { GetterTree } from "vuex";

import { AuthStateInterface } from "../models/AuthState.interface";
import { authStoreTypes } from "./types";
import { KoruStateInterface } from "@/core/modules/store/models/KoruState.interface";
import { UserInterface } from "@/core/modules/user/models/User.interface";
import { userStore } from "@/core/modules/user/store";
import { userStoreTypes } from "@/core/modules/user/store/types";

export const getters: GetterTree<AuthStateInterface, KoruStateInterface> = {
  [authStoreTypes.getters.isLoggedIn]: (state: AuthStateInterface): boolean => {
    return state.isLoggedIn;
  },
  [authStoreTypes.getters.getUser]: (state: AuthStateInterface): UserInterface | undefined => {
    const user: UserInterface | undefined = state.user;
    userStore.action(userStoreTypes.actions.updateUserLastSeen, user);

    return user;
  },
  [authStoreTypes.getters.isLoading]: (state: AuthStateInterface): boolean => {
    return state.loading;
  },
};
