import { registerRoutes } from "@/core/modules/helpers";

import { blockRoutes } from "@/features/modules/block/router";
import { dashboardRoutes } from "@/features/modules/dashboard/router";
import { mapPointRoutes } from "@/features/modules/map-point/router";
import { mapRoutes } from "@/features/modules/map/router";
import { pageRoutes } from "@/features/modules/page/router";
import { settingRoutes } from "@/features/modules/setting/router";
import { templateRoutes } from "@/features/modules/template/router";
import { variableRoutes } from "@/features/modules/variable/router";

export const registerFeaturesRoutes = () => {
  registerRoutes(blockRoutes);
  registerRoutes(dashboardRoutes);
  registerRoutes(mapRoutes);
  registerRoutes(mapPointRoutes);
  registerRoutes(pageRoutes);
  registerRoutes(settingRoutes);
  registerRoutes(templateRoutes);
  registerRoutes(variableRoutes);
};
