import { DocumentInterface, createDocument, documentFromFirestore, documentToFirestore } from "@/core/modules/database/models/Document.interface";

export interface MailMessageInterface extends DocumentInterface {
  recipientName: string;
  recipientEmail: string;
  subject: string;
  message: string;
}

export const createNewMailMessage = (): MailMessageInterface => {
  return createDocument<MailMessageInterface>({
    interfaceType: "MailMessage",
    recipientName: undefined,
    recipientEmail: undefined,
    subject: undefined,
    message: undefined,
  });
};

export function mailMessageFromFirestore(data: Record<string, unknown>, id?: string): MailMessageInterface {
  return documentFromFirestore<MailMessageInterface>(
    id != undefined ? id : (data.id as string),
    {
      interfaceType: "MailMessage",
      recipientName: data.recipientName,
      recipientEmail: data.recipientEmail,
      subject: data.subject,
      message: data.message,
    },
    data
  );
}

export function mailMessageToFirestore(mailMessage: MailMessageInterface): Record<string, unknown> {
  return documentToFirestore(
    {
      recipientName: mailMessage.recipientName,
      recipientEmail: mailMessage.recipientEmail,
      subject: mailMessage.subject,
      message: mailMessage.message,
    },
    mailMessage
  );
}
