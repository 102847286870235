import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import { koruConfig } from "@/core/modules/config";
import { UserInterface } from "@/core/modules/user/models/User.interface";
import { UserModelInterface } from "@/core/modules/user/models/UserModel.interface";

import { createFirestoreConverter, getCollectionReference } from "@/core/modules/helpers";

export const searchDocuments = async (userModel: UserModelInterface, text: string): Promise<UserInterface[]> => {
  try {
    const snapshot: firebase.firestore.QuerySnapshot<UserInterface> = await getCollectionReference(userModel.collectionName)
      .withConverter(createFirestoreConverter(userModel.documentFromFirestore, userModel.documentToFirestore))
      .where("searchKeys", "array-contains", text)
      .orderBy("fullName")
      .limit(koruConfig.settings["searchResultLimit"] as number)
      .get();

    if (snapshot == undefined || snapshot.empty) return [];

    return snapshot.docs.map((doc) => doc.data());
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
};
