export const it = {
  setting: {
    defaultDescription: "Descrizione generica",
    defaultKeywords: "Parole chiave generiche",
    defaultLanguage: "Lingua principale",
    defaultLanguageError: "Scegliere la lingua principale",
    defaultTitle: "Titolo pagina generico",
    languages: "Lingue",
    languagesError: "Scegliere almeno una lingua",
    settings: "Impostazioni",
  },
  navigation: {
    setting: "Impostazioni",
  },
  roleModules: {
    setting: "Impostazioni",
  },
};
