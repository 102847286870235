import { BackupInterface } from "@/core/modules/backup/models/Backup.interface";
import { DatabaseModuleInterface } from "@/core/modules/database/models/DatabaseModule.interface";
import { koruDb } from "@/core/modules/database";

export const readDocuments = async (): Promise<BackupInterface[]> => {
  try {
    return await koruDb.getModule<DatabaseModuleInterface<BackupInterface>>("backups").getDocuments();
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
};
