import { NotificationInterface } from "@/core/modules/notification/models/Notification.interface";

export const readNotificationsMatrix = (notifications: NotificationInterface[]): Record<string, number> => {
  try {
    const notificationsMatrix: Record<string, number> = {};
    for (const notification of notifications) {
      if (notification.type != undefined) {
        if (Object.keys(notificationsMatrix).indexOf(notification.type) >= 0) {
          notificationsMatrix[notification.type]++;
        } else {
          notificationsMatrix[notification.type] = 1;
        }
      }
    }
    return notificationsMatrix;
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
};
