import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import { BlockInterface } from "@/features/modules/block/models/Block.interface";
import { BlockModelInterface } from "@/features/modules/block/models/BlockModel.interface";

import { createFirestoreConverter, getCollectionReference } from "@/core/modules/helpers";

export const countBlocks = async (blockModel: BlockModelInterface): Promise<number> => {
  try {
    const snapshot: firebase.firestore.QuerySnapshot<BlockInterface> = await getCollectionReference(blockModel.collectionName)
      .withConverter(createFirestoreConverter(blockModel.documentFromFirestore, blockModel.documentToFirestore))
      .get();

    if (snapshot == undefined || snapshot.empty) return 0;

    return snapshot.docs.length;
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
};
