import { koruAuth } from "@/core/modules/auth";
import { koruDb } from "@/core/modules/database";
import { UserInterface } from "@/core/modules/user/models/User.interface";
import { UserModelInterface } from "@/core/modules/user/models/UserModel.interface";

import { setDocumentFields } from "@/core/modules/helpers";

export const toggleUser = async (user: UserInterface): Promise<void> => {
  try {
    if (user.blocked == false) {
      user.blocked = true;
      await koruAuth.blockUser(user.id);
    } else {
      user.blocked = false;
      await koruAuth.unblockUser(user.id);
    }
    setDocumentFields(user.id, user);
    await koruDb.getModule<UserModelInterface>("users").updateDocument(user, true);
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
};
