import { ActionContext } from "vuex";

import { BackupStateInterface } from "@/core/modules/backup/models/BackupState.interface";
import { backupStoreTypes } from "../types";
import { KoruStateInterface } from "@/core/modules/store/models/KoruState.interface";

import { importBackup } from "@/core/plugins/import-export";

export const restoreBackup = async (context: ActionContext<BackupStateInterface, KoruStateInterface>): Promise<void> => {
  try {
    const options = { autoParseDates: true, autoParseGeos: false, showLogs: true };
    const uploadedData: string | undefined = await context.getters[backupStoreTypes.getters.getUploadedData];
    if (uploadedData == undefined) {
      throw new Error("backupDataNotSet");
    }
    await importBackup(JSON.parse(uploadedData), options);
  } catch (error: unknown) {
    context.commit(backupStoreTypes.mutations.resetUploadedData);
    throw new Error((error as Error).message);
  } finally {
    context.commit(backupStoreTypes.mutations.resetUploadedData);
  }
};
