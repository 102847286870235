
import { defineComponent } from "vue";

import { koruStoreTypes } from "@/core/modules/store/types";
import { useKoruLocale } from "@/core/modules/locale";
import { useKoruStore } from "@/core/modules/store";

export default defineComponent({
  name: "KoruContainer",
  props: {
    backButton: { type: Boolean, default: false },
    editRoute: { type: String, default: undefined },
    type: { type: String, default: "full" },
    subtitle: { type: String, default: undefined },
    title: { type: String, default: undefined },
  },
  setup(props) {
    const { t } = useKoruLocale();
    const koruStore = useKoruStore();

    const classes = {
      card: true,
      "w-full": true,
      "xl:w-8": props.type == "boxed",
    };

    const goBack = () => {
      koruStore.dispatch(koruStoreTypes.actions.navigateBack);
    };

    const goToEdit = () => {
      koruStore.dispatch(koruStoreTypes.actions.navigateTo, props.editRoute);
    };

    return {
      classes,
      goBack,
      goToEdit,
      hasBackButton: props.backButton,
      hasEditRoute: props.editRoute != undefined,
      t,
    };
  },
});
