export const it = {
  role: {
    module: "Modulo",
    name: "Nome",
    nameError: "Inserisci un nome valido",
    role: "Ruolo",
    rights: "Permessi",
    roles: "Ruoli",
  },
};
