export function getDayOfISOWeek(week: number, year: number, dayOfWeek: number): Date {
  const date: Date = getStartDateOfISOWeek(week, year);
  date.setDate(date.getDate() + (+dayOfWeek || 7) - 1);
  return date;
}

export function getStartDateOfISOWeek(week: number, year: number): Date {
  // Get date for 1 Jan in given year
  const date: Date = new Date(year, 0, 1);
  const dayOfWeek: number = date.getDay();
  // Shift to start of ISO week 1
  date.setDate((dayOfWeek <= 4 ? 2 : 9) - date.getDay());
  // Add required number of weeks
  date.setDate(date.getDate() + (week - 1) * 7);

  return date;
}

export function randomIntBetween(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function randomElementFromArray<T>(array: T[]): T {
  return array[Math.floor(Math.random() * array.length)];
}

export function roundNumber(number: number, digits: number): number {
  const pow = Math.pow(10, digits);
  return Math.round((number + Number.EPSILON) * pow) / pow;
}

export function slugify(...args: (string | number)[]): string {
  const value = args.join(" ");
  return value
    .normalize("NFD") // split an accented letter in the base letter and the acent
    .replace(/[\u0300-\u036f]/g, "") // remove all previously split accents
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9 ]/g, "") // remove all chars not letters, numbers and spaces (to be replaced)
    .replace(/\s+/g, "-"); // separator
}
