import { BackupInterface } from "@/core/modules/backup/models/Backup.interface";
import { DatabaseModuleInterface } from "@/core/modules/database/models/DatabaseModule.interface";
import { koruDb } from "@/core/modules/database";

export const deleteDocument = async (backup: BackupInterface): Promise<void> => {
  try {
    await koruDb.getModule<DatabaseModuleInterface<BackupInterface>>("backups").deleteDocument(backup, true);
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
};
