import { ChangelogModelInterface } from "@/core/modules/changelog/models/ChangelogModel.interface";
import { koruDb } from "@/core/modules/database";
import { ReleaseInterface } from "@/core/modules/changelog/models/Release.interface";

import { setDocumentFields } from "@/core/modules/helpers";

export const createDocument = async (release: ReleaseInterface): Promise<string> => {
  try {
    setDocumentFields(release.id, release);
    return await koruDb.getModule<ChangelogModelInterface>("changelog").createDocument(release, false);
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
};
