import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import { UserInterface } from "@/core/modules/user/models/User.interface";
import { UserModelInterface } from "@/core/modules/user/models/UserModel.interface";

import { createFirestoreConverter, getCollectionReference } from "@/core/modules/helpers";

export const isUserEmailUnique = async (userModel: UserModelInterface, email: string, oldEmail: string): Promise<boolean> => {
  try {
    if (email == undefined) return false;
    if (oldEmail == undefined) oldEmail = "nil";
    const snapshot: firebase.firestore.QuerySnapshot<UserInterface> = await getCollectionReference(userModel.collectionName)
      .withConverter(createFirestoreConverter(userModel.documentFromFirestore, userModel.documentToFirestore))
      .where("email", "==", email)
      .where("email", "!=", oldEmail)
      .limit(1)
      .get();

    return snapshot.size == 0;
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
};
