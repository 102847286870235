export const en = {
  template: {
    addBlock: "Aggiungi blocco",
    contentBlock: "Contenuto",
    name: "Nome",
    nameError: "Inserire un nome valido",
    reorder: "Riordina",
    template: "Modello",
    templates: "Modelli",
  },
  buttons: {
    applyToOtherLanguages: "Applica ad altre lingue",
    clone: "Duplica",
  },
  navigation: {
    template: "Modelli",
  },
  roleModules: {
    template: "Modelli",
  },
};
