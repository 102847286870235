import { createStoreModule } from "@/core/modules/helpers";
import { EmptyStateInterface } from "@/core/modules/store/models/EmptyState.interface";
import { KoruStoreModuleInterface } from "@/core/modules/store/models/KoruStoreModule.interface";

import { actions } from "./actions";

export const mailMessageStore: KoruStoreModuleInterface<EmptyStateInterface> = createStoreModule("mailMessageState", undefined, undefined, actions);

export const useMailMessageStore = (): KoruStoreModuleInterface<EmptyStateInterface> => {
  return mailMessageStore;
};
