import { ActionTree } from "vuex";

import { EmptyStateInterface } from "@/core/modules/store/models/EmptyState.interface";
import { koruDb } from "@/core/modules/database";
import { KoruStateInterface } from "@/core/modules/store/models/KoruState.interface";
import { LogInterface } from "../models/Log.interface";
import { LogModelInterface } from "../models/LogModel.interface";
import { logStoreTypes } from "./types";

export const actions: ActionTree<EmptyStateInterface, KoruStateInterface> = {
  async [logStoreTypes.actions.getLogsByRange](context, payload: Record<string, Date>): Promise<LogInterface[]> {
    try {
      const startDate: Date = payload.startDate;
      const endDate: Date = payload.endDate;

      return await koruDb.getModule<LogModelInterface>("logs").getLogsByRange(startDate, endDate);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
};
