import { koruDb } from "@/core/modules/database";
import { NotificationInterface } from "@/core/modules/notification/models/Notification.interface";
import { NotificationModelInterface } from "@/core/modules/notification/models/NotificationModel.interface";

export const readDocument = async (documentId: string): Promise<NotificationInterface> => {
  try {
    return await koruDb.getModule<NotificationModelInterface>("notifications").getDocument(documentId);
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
};
