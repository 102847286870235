import { koruDb } from "@/core/modules/database";
import { UserModelInterface } from "@/core/modules/user/models/UserModel.interface";

export const isEmailUnique = async (email: string, oldEmail: string): Promise<boolean> => {
  try {
    return await koruDb.getModule<UserModelInterface>("users").isUserEmailUnique(email, oldEmail);
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
};
