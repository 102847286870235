import { createNewMailMessage } from "@/core/modules/mail-message/models/MailMessage.interface";
import { mailMessageStore } from "@/core/modules/mail-message/store";
import { mailMessageStoreTypes } from "@/core/modules/mail-message/store/types";
import { WorkbenchModuleInterface } from "../models/WorkbenchModule.interface";

export const sendTestMail: WorkbenchModuleInterface = {
  name: "sendTestMail",
  label: "workbench.sendTestMail",
  action: async (): Promise<void> => {
    return await performAction();
  },
};

async function performAction(): Promise<void> {
  try {
    const mailMessage = createNewMailMessage();
    mailMessage.recipientName = "Marco Lisatti";
    mailMessage.recipientEmail = "marco.lisatti@gmail.com";
    mailMessage.subject = "Messaggio di prova";
    mailMessage.message = "Ciao.\n\nQuesto è un messaggio di prova che dovrebbe essere recapitato.\n\nBuon lavoro!\n\nIl Team di KORU";

    await mailMessageStore.action(mailMessageStoreTypes.actions.createMailMessage, mailMessage);
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
}
