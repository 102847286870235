import { koruDb } from "@/core/modules/database";
import { UserInterface } from "@/core/modules/user/models/User.interface";
import { UserModelInterface } from "@/core/modules/user/models/UserModel.interface";

import { runBeforeDeleteFunction } from "@/core/modules/helpers";

export const deleteDocument = async (user: UserInterface): Promise<void> => {
  try {
    await runBeforeDeleteFunction("functionsCoreUserBeforeDelete", user.id);

    await koruDb.getModule<UserModelInterface>("users").deleteDocument(user, true);
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
};
