export const roleStoreTypes = {
  actions: {
    // read documents
    getRoles: "GET_ROLES",

    // read document
    getRole: "GET_ROLE",

    // create document
    createRole: "CREATE_ROLE",

    // update document
    updateRole: "UPDATE_ROLE",

    // delete document
    deleteRole: "DELETE_ROLE",

    // matrix converters
    fromMatrixToValues: "FROM_MATRIX_TO_VALUES",
    fromValuesToMatrix: "FROM_VALUES_TO_MATRIX",
  },
};
