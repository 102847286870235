export const itFeaturesLabels = {
  buttons: {},
  navigation: {},
  roleModules: {},
  toast: {
    error: {},
    success: {},
    warning: {},
  },
};
