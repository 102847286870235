import { authStoreTypes } from "@/core/modules/auth/store/types";
import { DocumentInterface } from "../database/models/Document.interface";
import { useAuthStore } from "@/core/modules/auth/store";
import { UserInterface } from "@/core/modules/user/models/User.interface";
import { getObjectValueWithField } from ".";

export function userHasRoleByName(roleName: string): boolean {
  const authStore = useAuthStore();
  const user: UserInterface = authStore.getter(authStoreTypes.getters.getUser);
  if (user == undefined) return false;
  if (user.role == undefined) return false;
  return user.role.name == roleName;
}

export function userCanRead(module: string, document?: DocumentInterface): boolean {
  const authStore = useAuthStore();
  const user: UserInterface = authStore.getter(authStoreTypes.getters.getUser);
  if (user == undefined) return false;
  if (user.role.readAll.indexOf(module) >= 0 || (user.role.readOwn.indexOf(module) >= 0 && document && document.createdBy == user.id)) {
    return true;
  }
  return false;
}

export function userCanCreate(module: string): boolean {
  const authStore = useAuthStore();
  const user: UserInterface = authStore.getter(authStoreTypes.getters.getUser);
  if (user == undefined) return false;
  if (user.role.create.indexOf(module) >= 0) {
    return true;
  }
  return false;
}

export function userCanUpdate(module: string, document?: DocumentInterface): boolean {
  const authStore = useAuthStore();
  const user: UserInterface = authStore.getter(authStoreTypes.getters.getUser);
  if (user == undefined) return false;
  if (user.role.updateAll.indexOf(module) >= 0 || (user.role.updateOwn.indexOf(module) >= 0 && document && document.createdBy == user.id)) {
    return true;
  }
  return false;
}

export function userCanDelete(module: string, document?: DocumentInterface): boolean {
  const authStore = useAuthStore();
  const user: UserInterface = authStore.getter(authStoreTypes.getters.getUser);
  if (user == undefined) return false;
  if (user.role.deleteAll.indexOf(module) >= 0 || (user.role.deleteOwn.indexOf(module) >= 0 && document && document.createdBy == user.id)) {
    return true;
  }
  return false;
}

export function userHasReadRight(user: UserInterface, right: string): boolean {
  if (right == "any" || (user.role?.readAll as Array<string>).includes(right) || (user.role?.readOwn as Array<string>).includes(right)) {
    return true;
  }
  return false;
}

export function userHasAnyReadRight(user: UserInterface, rights: string[]): boolean {
  if (rights.length == 1 && rights[0] == "any") return true;

  for (const right of rights) {
    if ((user.role?.readAll as Array<string>).includes(right) || (user.role?.readOwn as Array<string>).includes(right)) {
      return true;
    }
  }
  return false;
}

export function userHasRightWithType(user: UserInterface, module: string, rights: Array<string>): boolean {
  if (rights.length <= 0) {
    return true;
  }

  for (const right of rights) {
    const allowedModules = getObjectValueWithField(user.role, right) as Array<string>;
    if (allowedModules.includes(module)) return true;
  }
  return false;
}
