export const en = {
  role: {
    module: "Module",
    name: "Name",
    nameError: "Insert a valid name",
    role: "Role",
    rights: "Rights",
    roles: "Roles",
  },
};
