export const en = {
  variable: {
    name: "Nome",
    nameError: "Inserire un nome valido",
    value: "Valore",
    valueError: "Inserire un valore valido",
    variable: "Variabile",
    variables: "Variabili",
  },
  navigation: {
    variable: "Variabili",
  },
  roleModules: {
    variable: "Variabili",
  },
};
