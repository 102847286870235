export const en = {
  mapPoint: {
    content: "Contenuto",
    image: "Immagine",
    images: "Immagini",
    mapPoint: "Punto mappa",
    mapPointsOf: "Punti mappa di",
    name: "Nome",
    nameError: "Inserire un nome valido",
    noImage: "Nessuna immagine presente",
    position: "Posizione",
    title: "Titolo",
    titleError: "Inserire un titolo valido",
    unknown: "sconosciuto",
    uploadImage: "Carica immagine",
  },
  roleModules: {
    mapPoint: "Punti mappa",
  },
};
