import { ChangelogModelInterface } from "@/core/modules/changelog/models/ChangelogModel.interface";
import { koruDb } from "@/core/modules/database";
import { ReleaseInterface } from "@/core/modules/changelog/models/Release.interface";

import { hasDocumentChanged, setDocumentFields } from "@/core/modules/helpers";

export const updateDocument = async (release: ReleaseInterface): Promise<void> => {
  try {
    const oldRelease: ReleaseInterface = await koruDb.getModule<ChangelogModelInterface>("changelog").getDocument(release.id);
    if (hasDocumentChanged(release, oldRelease)) throw new Error("sync");

    setDocumentFields(release.id, release);
    await koruDb.getModule<ChangelogModelInterface>("changelog").updateDocument(release, false);
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
};
