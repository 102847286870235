import { koruDb } from "@/core/modules/database";
import { UserInterface } from "@/core/modules/user/models/User.interface";
import { UserModelInterface } from "@/core/modules/user/models/UserModel.interface";

export const checkInstallStatus = async (): Promise<string> => {
  try {
    const users: UserInterface[] = await koruDb.getModule<UserModelInterface>("users").getDocuments();

    return users.length > 0 ? "installed" : "installNeeded";
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
};
