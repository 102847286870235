import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import { koruConfig } from "@/core/modules/config";
import { RoleInterface } from "@/core/modules/role/models/Role.interface";
import { RoleModelInterface } from "../../RoleModel.interface";

import { createFirestoreConverter, getCollectionReference } from "@/core/modules/helpers";

export const searchDocuments = async (roleModel: RoleModelInterface, text: string): Promise<RoleInterface[]> => {
  try {
    const snapshot: firebase.firestore.QuerySnapshot<RoleInterface> = await getCollectionReference(roleModel.collectionName)
      .withConverter(createFirestoreConverter(roleModel.documentFromFirestore, roleModel.documentToFirestore))
      .where("searchKeys", "array-contains", text)
      .orderBy("name")
      .limit(koruConfig.settings["searchResultLimit"] as number)
      .get();

    if (snapshot == undefined || snapshot.empty) return [];

    return snapshot.docs.map((doc) => doc.data());
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
};
