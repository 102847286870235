export const en = {
  workbench: {
    action: "Action",
    actionSelect: "Select an action",
    collections: "Collections",
    collectionsSelect: "Select one or more collections",
    deleteData: "Delete one or more collections",
    exportData: "Export one or more collections",
    file: "File",
    fileSelect: "Select a file to import",
    importData: "Import collections from backup",
    importExcel: "Import data from an excel file",
    go: "Go",
    workbench: "Workbench",
  },
};
