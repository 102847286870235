import { DocumentInterface, createDocument, documentFromFirestore, documentToFirestore } from "@/core/modules/database/models/Document.interface";
import { LinkedBlockInterface, linkedBlockFromFirestore, linkedBlockToFirestore } from "@/features/modules/block/models/LinkedBlock.interface";

export interface TemplateInterface extends DocumentInterface {
  name: string;
  blocks: Record<string, LinkedBlockInterface[]>;
  blocksIds: string[];
}

export const createNewTemplate = (): TemplateInterface => {
  return createDocument<TemplateInterface>({
    interfaceType: "Template",
    name: "",
    blocks: {},
    blocksIds: [],
  });
};

export function templateFromFirestore(data: Record<string, unknown>, id?: string): TemplateInterface {
  const blocks: Record<string, LinkedBlockInterface[]> = {};

  for (const [key, value] of Object.entries(data.blocks as Record<string, unknown>)) {
    blocks[key] = (value as Record<string, unknown>[]).map((block: Record<string, unknown>) => {
      return linkedBlockFromFirestore(block);
    });
  }

  return documentFromFirestore<TemplateInterface>(
    id != undefined ? id : (data.id as string),
    {
      interfaceType: "Template",
      name: data.name as string,
      blocks: blocks,
      blocksIds: data.blocksIds || [],
    },
    data
  );
}

export function templateToFirestore(template: TemplateInterface): Record<string, unknown> {
  const blocks: Record<string, unknown> = {};
  const blocksIds: string[] = [];
  for (const [key, value] of Object.entries(template.blocks)) {
    blocks[key] = value.map((block: LinkedBlockInterface) => {
      if (block.id != "content" && !blocksIds.includes(block.id)) blocksIds.push(block.id);
      return linkedBlockToFirestore(block);
    });
  }

  return documentToFirestore(
    {
      name: template.name,
      blocks: blocks,
      blocksIds: blocksIds,
    },
    template
  );
}
