import { registerLocaleModule } from "@/core/modules/helpers/locale-helpers";

import { blockLocaleModule } from "@/features/modules/block/locales";
import { mapLocaleModule } from "@/features/modules/map/locales";
import { mapPointLocaleModule } from "@/features/modules/map-point/locales";
import { pageLocaleModule } from "@/features/modules/page/locales";
import { sectionLocaleModule } from "@/features/modules/section/locales";
import { settingLocaleModule } from "@/features/modules/setting/locales";
import { templateLocaleModule } from "@/features/modules/template/locales";
import { variableLocaleModule } from "@/features/modules/variable/locales";

export const registerFeaturesLocales = () => {
  registerLocaleModule(blockLocaleModule);
  registerLocaleModule(mapLocaleModule);
  registerLocaleModule(mapPointLocaleModule);
  registerLocaleModule(pageLocaleModule);
  registerLocaleModule(sectionLocaleModule);
  registerLocaleModule(settingLocaleModule);
  registerLocaleModule(templateLocaleModule);
  registerLocaleModule(variableLocaleModule);
};
