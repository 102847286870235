import { BackupInterface, createNewBackup, backupFromFirestore, backupToFirestore } from "@/core/modules/backup/models/Backup.interface";
import { BackupModelInterface } from "../BackupModel.interface";

import { deleteDocumentHelper, getDocumentsHelper } from "@/core/modules/helpers";

export const backupModel: BackupModelInterface = {
  collectionName: "backups",
  documentFromFirestore: backupFromFirestore,
  documentToFirestore: backupToFirestore,

  async getDocuments(): Promise<BackupInterface[]> {
    return await getDocumentsHelper<BackupInterface>(
      this.collectionName,
      "createdAt",
      "desc",
      0,
      0,
      this.documentFromFirestore,
      this.documentToFirestore
    );
  },
  async getDocument(): Promise<BackupInterface> {
    return createNewBackup();
  },
  async createDocument(): Promise<string> {
    return Promise.resolve("");
  },
  async updateDocument(): Promise<void> {
    return Promise.resolve();
  },
  async deleteDocument(document: BackupInterface, logAction: boolean): Promise<void> {
    await deleteDocumentHelper(document, this.collectionName, logAction);
  },
  async searchDocuments(): Promise<BackupInterface[]> {
    return Promise.resolve([]);
  },
};
