import { MailMessageInterface, mailMessageFromFirestore, mailMessageToFirestore, createNewMailMessage } from "../MailMessage.interface";
import { MailMessageModelInterface } from "../MailMessageModel.interface";

import { createDocumentHelper } from "@/core/modules/helpers";

export const mailMessageModel: MailMessageModelInterface = {
  collectionName: "mailMessages",
  documentFromFirestore: mailMessageFromFirestore,
  documentToFirestore: mailMessageToFirestore,

  async getDocuments(): Promise<MailMessageInterface[]> {
    return [];
  },
  async getDocument(): Promise<MailMessageInterface> {
    return Promise.resolve(createNewMailMessage());
  },
  async createDocument(document: MailMessageInterface, logAction: boolean): Promise<string> {
    return await createDocumentHelper<MailMessageInterface>(
      document,
      this.collectionName,
      this.documentFromFirestore,
      this.documentToFirestore,
      logAction
    );
  },
  async updateDocument(): Promise<void> {
    return Promise.resolve();
  },
  async deleteDocument(): Promise<void> {
    return Promise.resolve();
  },
  async searchDocuments(): Promise<MailMessageInterface[]> {
    return Promise.resolve([]);
  },
};
