export const it = {
  user: {
    activeTrue: "Attivo",
    activeFalse: "Non Attivo",
    allUsers: "Tutti gli Utenti",
    email: "E-mail",
    emailAddress: "Indirizzo E-mail",
    emailAddressError: "Inserisci un indirizzo e-mail valido e non già utilizzato",
    firstName: "Nome",
    firstNameError: "Inserisci un nome valido",
    fullName: "Nome",
    lastName: "Cognome",
    lastNameError: "Inserisci un cognome valido",
    name: "Nome",
    registration: "Iscrizione",
    role: "Ruolo",
    roleError: "Scegli un ruolo valido",
    roleSelect: "Seleziona un ruolo",
    state: "Stato",
    user: "Utente",
    users: "Utenti",
  },
};
