import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";

import { koruConfig } from "@/core/modules/config";
import { koruRouter } from "@/core/modules/router";

export function resetLocalStorageIfNeeded(): void {
  if (!localStorage.getItem("koruVersion")) {
    sessionStorage.clear();
    localStorage.clear();
    localStorage.setItem("koruVersion", koruConfig.app.version);
  }

  const currentAppVersion: string = localStorage.getItem("koruVersion") as string;

  console.log("KORU Version", currentAppVersion);

  if (currentAppVersion !== koruConfig.app.version) {
    sessionStorage.clear();
    localStorage.clear();
    localStorage.setItem("koruVersion", koruConfig.app.version);
  }
}

export function goToErrorPage(error: string): void {
  localStorage.setItem("lastError", error);
  koruRouter.push("/error");
}

export function inputSelectAll(val: PointerEvent): void {
  const target: HTMLInputElement = val.target as HTMLInputElement;
  if (target != undefined) target.setSelectionRange(0, target.value.length);
}

export function validationHasErrorAtFieldAndIndex(validation: Record<string, unknown>, field: string, index: number): boolean {
  const each: Record<string, unknown> = validation.$each as Record<string, unknown>;
  const response: Record<string, unknown> = each.$response as Record<string, unknown>;
  const errors: Record<string, unknown>[] = response.$errors as Record<string, unknown>[];
  const fieldErrors: Record<string, unknown>[] = errors[index][field] as Record<string, unknown>[];

  return fieldErrors.length > 0;
}

export function showDebug(moduleName: string, message: string): void {
  console.log(`[DEBUG] ${moduleName}: ${message}`);
}

export function initSentry(): void {
  if (koruConfig.sentry.dsn != "") {
    Sentry.init({
      dsn: koruConfig.sentry.dsn,
      integrations: [new BrowserTracing()],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    });
  }
}
