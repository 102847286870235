import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import { UserInterface } from "@/core/modules/user/models/User.interface";
import { UserModelInterface } from "@/core/modules/user/models/UserModel.interface";

import { createFirestoreConverter, getCollectionReference } from "@/core/modules/helpers";

export const getUsersIdsByModuleAndRight = async (userModel: UserModelInterface, module: string, right: string): Promise<string[]> => {
  try {
    let snapshot: firebase.firestore.QuerySnapshot<UserInterface>;

    if (module == "any" || right == "any") {
      snapshot = await getCollectionReference(userModel.collectionName)
        .withConverter(createFirestoreConverter(userModel.documentFromFirestore, userModel.documentToFirestore))
        .orderBy("fullName")
        .get();
    } else {
      snapshot = await getCollectionReference(userModel.collectionName)
        .withConverter(createFirestoreConverter(userModel.documentFromFirestore, userModel.documentToFirestore))
        .where(`role.${right}`, "array-contains", module)
        .orderBy("fullName")
        .get();
    }

    if (snapshot == undefined || snapshot.empty) return [];

    return snapshot.docs.map((doc) => doc.data().id);
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
};
