export const it = {
  backup: {
    backup: "Backup",
    backups: "Backup",
    createdAt: "Creazione",
    createBackup: "Crea Backup Ora",
    createBackupDescription: "Il backup è in fase di creazione e apparirà nella lista non appena sarà disponibile",
    createBackupTitle: "Creazione Backup",
    daily: "Giornaliero",
    type: "Tipo",
    types: {
      daily: "Giornaliero",
      monthly: "Mensile",
      request: "Su richiesta",
      weekly: "Settimanale",
    },
    user: "Utente",
  },
};
