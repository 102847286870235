import { RoleInterface } from "../models/Role.interface";

/* set search keys */

export const setSearchKeys = (role: RoleInterface): string[] => {
  const searchKeys: string[] = [];
  if (role.name != undefined) searchKeys.push(role.name.toLowerCase());

  return searchKeys;
};
