import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import { WebRenderInterface, webRenderFromFirestore, webRenderToFirestore } from "@/features/modules/web-render/models/WebRender.interface";
import { WebRenderModelInterface } from "@/features/modules/web-render/models/WebRenderModel.interface";

import {
  createDocumentHelper,
  createFirestoreConverter,
  deleteDocumentHelper,
  getCollectionReference,
  getDocumentHelper,
  updateDocumentHelper,
} from "@/core/modules/helpers";

export const webRenderModel: WebRenderModelInterface = {
  collectionName: "webRenders",
  documentFromFirestore: webRenderFromFirestore,
  documentToFirestore: webRenderToFirestore,

  async getDocuments(): Promise<WebRenderInterface[]> {
    return Promise.resolve([]);
  },
  async getDocument(documentId: string): Promise<WebRenderInterface> {
    return await getDocumentHelper<WebRenderInterface>(documentId, this.collectionName, this.documentFromFirestore, this.documentToFirestore);
  },
  async createDocument(document: WebRenderInterface, logAction: boolean): Promise<string> {
    return await createDocumentHelper<WebRenderInterface>(
      document,
      this.collectionName,
      this.documentFromFirestore,
      this.documentToFirestore,
      logAction
    );
  },
  async updateDocument(document: WebRenderInterface, logAction: boolean): Promise<void> {
    await updateDocumentHelper<WebRenderInterface>(document, this.collectionName, this.documentFromFirestore, this.documentToFirestore, logAction);
  },
  async deleteDocument(document: WebRenderInterface, logAction: boolean): Promise<void> {
    await deleteDocumentHelper(document, this.collectionName, logAction);
  },
  async getWebRenderByTypeAndReferenceAndLanguage(type: string, reference: string, language: string): Promise<WebRenderInterface | undefined> {
    try {
      const snapshot: firebase.firestore.QuerySnapshot<WebRenderInterface> = await getCollectionReference(this.collectionName)
        .withConverter(createFirestoreConverter(this.documentFromFirestore, this.documentToFirestore))
        .where("type", "==", type)
        .where("reference", "==", reference)
        .where("language", "==", language)
        .get();

      if (snapshot.empty) {
        return undefined;
      } else {
        return snapshot.docs[0].data();
      }
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async searchDocuments(): Promise<WebRenderInterface[]> {
    return Promise.resolve([]);
  },
};
