import { registerDbModule } from "@/core/modules/helpers";

import { backupModel } from "@/core/modules/backup/models/firestore/Backup.model";
import { changelogModel } from "@/core/modules/changelog/models/firestore/Changelog.model";
import { feedbackModel } from "@/core/modules/feedback/models/firestore/Feedback.model";
import { logModel } from "@/core/modules/log/models/firestore/Log.model";
import { mailMessageModel } from "@/core/modules/mail-message/models/firestore/MailMessage.model";
import { notificationModel } from "@/core/modules/notification/models/firestore/Notification.model";
import { roleModel } from "@/core/modules/role/models/firestore/Role.model";
import { userModel } from "@/core/modules/user/models/firestore/User.model";

export const registerCoreDbModules = () => {
  registerDbModule(backupModel);
  registerDbModule(changelogModel);
  registerDbModule(feedbackModel);
  registerDbModule(logModel);
  registerDbModule(mailMessageModel);
  registerDbModule(notificationModel);
  registerDbModule(roleModel);
  registerDbModule(userModel);
};
