
import { computed, defineComponent, onMounted } from "vue";
import { useRoute } from "vue-router";

import { DocumentInterface } from "@/core/modules/database/models/Document.interface";
import { koruStoreTypes } from "@/core/modules/store/types";
import { useKoruLocale } from "@/core/modules/locale";
import { useKoruStore } from "@/core/modules/store";

import {
  showCloseConfirmDialog,
  showDeleteConfirmDialog,
  showToastWithWarningType,
  tryOrError,
  tryOrToast,
  userCanCreate,
  userCanDelete,
  userCanUpdate,
} from "@/core/modules/helpers";

export default defineComponent({
  name: "KoruEditor",
  props: {
    backAction: { type: Function },
    canCreate: { type: Boolean, default: true },
    canDelete: { type: Boolean, default: true },
    deleteAction: { type: Function },
    formSubmitted: { type: Boolean, default: false },
    isFormValid: { type: Boolean, default: false },
    loadAction: { type: Function },
    record: { type: Object },
    submitAction: { type: Function },
  },
  emits: ["update:formSubmitted"],
  setup(props, { emit }) {
    const { t } = useKoruLocale();
    const koruStore = useKoruStore();

    const route = useRoute();
    const requiredModule: string = route.meta.requiredModule as string;

    const formSubmittedRef = computed({
      get: () => props.formSubmitted,
      set: (val) => emit("update:formSubmitted", val),
    });

    const renderSubtitle = () => {
      if (props.record?.id == "new") {
        return t("gen.newPrefix");
      } else if (userCanUpdate(requiredModule, props.record as DocumentInterface)) {
        return t("gen.editPrefix");
      } else {
        return t("gen.viewPrefix");
      }
    };

    const handleLoad = async () => {
      await tryOrError(
        async () => {
          if (props.loadAction != undefined) await props.loadAction();
        },
        () => {
          koruStore.commit(koruStoreTypes.mutations.loadingStop);
          koruStore.commit(koruStoreTypes.mutations.editingStart);
        }
      );
    };

    const handleSubmit = async () => {
      formSubmittedRef.value = true;
      if (!props.isFormValid) {
        showToastWithWarningType("formInvalid", t);
        return;
      }
      koruStore.commit(koruStoreTypes.mutations.editingStop);
      koruStore.commit(koruStoreTypes.mutations.loadingStart);

      await tryOrToast(
        async () => {
          if (props.submitAction != undefined) await props.submitAction();

          if (props.backAction != undefined) {
            props.backAction();
          } else {
            await koruStore.dispatch(koruStoreTypes.actions.navigateTo, koruStore.getters[koruStoreTypes.getters.getPreviousRoute]);
          }
        },
        "save",
        t,
        () => koruStore.commit(koruStoreTypes.mutations.loadingStop)
      );
    };

    const handleDelete = async () => {
      koruStore.commit(koruStoreTypes.mutations.editingStop);
      koruStore.commit(koruStoreTypes.mutations.loadingStart);

      await tryOrToast(
        async () => {
          if (props.deleteAction != undefined) await props.deleteAction();

          if (props.backAction != undefined) {
            props.backAction();
          } else {
            await koruStore.dispatch(koruStoreTypes.actions.navigateTo, koruStore.getters[koruStoreTypes.getters.getPreviousRoute]);
          }
        },
        "delete",
        t,
        () => koruStore.commit(koruStoreTypes.mutations.loadingStop)
      );
    };

    const handleKeydown = (e: KeyboardEvent) => {
      if (e.key == "Enter") {
        const target: HTMLElement = e.target as HTMLElement;
        if (target.localName != "textarea") e.preventDefault();
      }
    };

    onMounted(() => {
      handleLoad();
    });

    return {
      props,
      handleDelete,
      handleKeydown,
      handleSubmit,
      koruStore,
      koruStoreTypes,
      renderSubtitle,
      requiredModule,
      t,
      userCanCreate,
      userCanDelete,
      userCanUpdate,
    };
  },
  methods: {
    closeEditor() {
      this.koruStore.commit(this.koruStoreTypes.mutations.editingStop);
      this.koruStore.dispatch(this.koruStoreTypes.actions.navigateBack);
    },
    confirmClose(event: Event) {
      showCloseConfirmDialog(
        event,
        () => {
          this.closeEditor();
        },
        this.t
      );
    },
    confirmDelete(event: Event) {
      showDeleteConfirmDialog(
        event,
        () => {
          this.handleDelete();
        },
        this.t
      );
    },
  },
});
