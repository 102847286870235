import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import { addHours, compareAsc } from "date-fns";

import { koruBatch } from "@/core/modules/batch";
import { UserInterface } from "@/core/modules/user/models/User.interface";

import { getCollectionReference } from "@/core/modules/helpers";

export const updateUserLastSeen = async (user: UserInterface): Promise<void> => {
  try {
    if (user == undefined) return;

    if (user.lastSeen != undefined && compareAsc(addHours(user.lastSeen, 1), new Date()) > 0) return;

    user.lastSeen = new Date();

    koruBatch.update(getCollectionReference("users").doc(user.id), { lastSeen: firebase.firestore.Timestamp.fromDate(user.lastSeen) });
    await koruBatch.commit();
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
};
