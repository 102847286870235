import { ActionTree } from "vuex";

import { EmptyStateInterface } from "@/core/modules/store/models/EmptyState.interface";
import { KoruStateInterface } from "@/core/modules/store/models/KoruState.interface";
import { UserInterface } from "../models/User.interface";
import { userStoreTypes } from "./types";

import {
  createDocument,
  deleteDocument,
  isEmailUnique,
  readDocument,
  readDocuments,
  toggleUser,
  updateDocument,
  updateUserLastSeen,
} from "./actions/index";

export const actions: ActionTree<EmptyStateInterface, KoruStateInterface> = {
  // read documents
  async [userStoreTypes.actions.getUsers](): Promise<UserInterface[]> {
    return await readDocuments();
  },

  // read document
  async [userStoreTypes.actions.getUser](context, userId: string): Promise<UserInterface> {
    return await readDocument(userId);
  },

  // create document
  async [userStoreTypes.actions.createUser](context, user: UserInterface): Promise<string> {
    return await createDocument(user);
  },

  // update document
  async [userStoreTypes.actions.updateUser](context, user: UserInterface): Promise<void> {
    await updateDocument(user);
  },
  async [userStoreTypes.actions.updateUserLastSeen](context, user: UserInterface): Promise<void> {
    await updateUserLastSeen(user);
  },
  async [userStoreTypes.actions.toggleUser](context, user: UserInterface): Promise<void> {
    await toggleUser(user);
  },

  // delete document
  async [userStoreTypes.actions.deleteUser](context, user: UserInterface): Promise<void> {
    await deleteDocument(user);
  },

  // is email unique
  async [userStoreTypes.actions.isEmailUnique](context, { email, oldEmail }): Promise<boolean> {
    return await isEmailUnique(email, oldEmail);
  },
};
