import { RouteRecordRaw } from "vue-router";

export const templateRoutes: Array<RouteRecordRaw> = [
  {
    path: "/templates",
    name: "TemplateList",
    component: () => import("../views/TemplateList.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "template",
      requiredRights: ["readAll", "readOwn"],
    },
  },
  {
    path: "/templates/:id/edit",
    name: "TemplateEdit",
    component: () => import("../views/TemplateEdit.vue"),
    props: (route) => ({ clone: route.query.clone }),
    meta: {
      requiresAuth: true,
      requiredModule: "template",
      requiredRights: ["create", "updateAll", "updateOwn"],
    },
  },
];
