import { ChangelogModelInterface } from "@/core/modules/changelog/models/ChangelogModel.interface";
import { koruDb } from "@/core/modules/database";
import { ReleaseInterface } from "@/core/modules/changelog/models/Release.interface";

export const readDocument = async (documentId: string): Promise<ReleaseInterface> => {
  try {
    return await koruDb.getModule<ChangelogModelInterface>("changelog").getDocument(documentId);
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
};
