export const it = {
  workbench: {
    action: "Azione",
    actionSelect: "Seleziona un'azione",
    collections: "Collezioni",
    deleteData: "Elimina una o più collezioni",
    exportData: "Esporta una o più collezioni",
    file: "File",
    fileSelect: "Seleziona un file per l'importazione",
    importData: "Importa collezioni da un backup",
    collectionsSelect: "Seleziona una o più collezioni",
    go: "Vai",
    sendTestMail: "Invia una mail di prova",
    setSearchKeys: "Imposta le chiavi di ricerca",
    workbench: "Banco di Lavoro",
  },
};
