import { MapInterface } from "@/features/modules/map/models/Map.interface";
import { TemplateInterface } from "@/features/modules/template/models/Template.interface";
import { WebDocumentInterface } from "@/features/modules/web-document/models/WebDocument.interface";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export async function additionalUpdateWebDocumentsByMap(map: MapInterface): Promise<void> {
  return Promise.resolve();
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export async function additionalUpdateWebDocumentsByTemplates(involvedTemplates: TemplateInterface[]): Promise<void> {
  return Promise.resolve();
}

export async function additionalUpdateAllWebDocuments(): Promise<void> {
  return Promise.resolve();
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export async function additionalSlugifyCheck(webDocument: WebDocumentInterface, language: string): Promise<boolean> {
  return Promise.resolve(true);
}
