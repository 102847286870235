export const workbenchStoreTypes = {
  getters: {
    getUploadedData: "GET_UPLOADED_DATA",
  },
  actions: {
    // perform action
    performAction: "PERFORM_ACTION",
  },
  mutations: {
    resetUploadedData: "RESET_UPLOADED_DATA",
    setUploadedData: "SET_UPLOADED_DATA",
  },
};
