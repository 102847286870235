import { ChangelogModelInterface } from "../ChangelogModel.interface";
import { ReleaseInterface, releaseFromFirestore, releaseToFirestore } from "@/core/modules/changelog/models/Release.interface";

import { createDocumentHelper, deleteDocumentHelper, getDocumentHelper, getDocumentsHelper, updateDocumentHelper } from "@/core/modules/helpers";

import { getPublicReleases } from "./methods";

export const changelogModel: ChangelogModelInterface = {
  collectionName: "changelog",
  documentFromFirestore: releaseFromFirestore,
  documentToFirestore: releaseToFirestore,

  async getDocuments(): Promise<ReleaseInterface[]> {
    return await getDocumentsHelper<ReleaseInterface>(
      this.collectionName,
      "date",
      "desc",
      0,
      0,
      this.documentFromFirestore,
      this.documentToFirestore
    );
  },
  async getDocument(documentId: string): Promise<ReleaseInterface> {
    return await getDocumentHelper<ReleaseInterface>(documentId, this.collectionName, this.documentFromFirestore, this.documentToFirestore);
  },
  async createDocument(document: ReleaseInterface, logAction: boolean): Promise<string> {
    return await createDocumentHelper<ReleaseInterface>(
      document,
      this.collectionName,
      this.documentFromFirestore,
      this.documentToFirestore,
      logAction
    );
  },
  async updateDocument(document: ReleaseInterface, logAction: boolean): Promise<void> {
    await updateDocumentHelper<ReleaseInterface>(document, this.collectionName, this.documentFromFirestore, this.documentToFirestore, logAction);
  },
  async deleteDocument(document: ReleaseInterface, logAction: boolean): Promise<void> {
    await deleteDocumentHelper(document, this.collectionName, logAction);
  },
  async searchDocuments(): Promise<ReleaseInterface[]> {
    return Promise.resolve([]);
  },

  /* custom methods */

  async getPublicReleases(): Promise<ReleaseInterface[]> {
    return getPublicReleases(this);
  },
};
