
import { defineComponent, Ref, ref } from "vue";

import { authStoreTypes } from "@/core/modules/auth/store/types";
import { koruEventBus } from "@/core/modules/event-bus";
import { koruStoreTypes } from "@/core/modules/store/types";
import { NavigationItemInterface } from "@/core/modules/navigation/models/NavigationItem.interface";
import { useAuthStore } from "@/core/modules/auth/store";
import { useKoruLocale } from "@/core/modules/locale";
import { useKoruStore } from "@/core/modules/store";
import { UserInterface } from "@/core/modules/user/models/User.interface";

import { userHasAnyReadRight } from "@/core/modules/helpers";

export default defineComponent({
  name: "submenu",
  emits: ["root-menuitem-click", "menuitem-click"],
  props: {
    items: Array,
    layoutMode: String,
    menuActive: Boolean,
    mobileMenuActive: Boolean,
    root: {
      type: Boolean,
      default: false,
    },
    parentMenuItemActive: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { t } = useKoruLocale();
    const koruStore = useKoruStore();
    const authStore = useAuthStore();
    const user: UserInterface = authStore.getter(authStoreTypes.getters.getUser);

    const activeIndex: Ref<number | undefined> = ref(undefined);

    const hasVisibleChildren = (item: NavigationItemInterface): boolean => {
      if (item.items == undefined) {
        return false;
      }
      for (var itemsIndex = 0; itemsIndex < item.items.length; itemsIndex++) {
        const child: NavigationItemInterface = item.items[itemsIndex];
        if (isChildVisible(child)) {
          return true;
        }
      }
      return false;
    };

    const isChildVisible = (item: NavigationItemInterface): boolean => {
      if (item.roleModules == undefined) {
        return false;
      }
      if (item.roleModules != undefined && userHasAnyReadRight(user, item.roleModules)) {
        return true;
      }
      return false;
    };

    const isLastItem = (index: number): boolean => {
      return index == Object.keys(props.items as Array<NavigationItemInterface>).length - 1;
    };

    return {
      activeIndex,
      hasVisibleChildren,
      isChildVisible,
      isLastItem,
      koruStore,
      koruStoreTypes,
      t,
    };
  },
  mounted(): void {
    koruEventBus.on("reset-active-index", () => {
      if (this.isSlim()) {
        this.activeIndex = undefined;
      }
    });
  },
  methods: {
    onMenuItemClick(event: Event, item: Record<string, (params?: unknown) => void>, index: number): void {
      if (item.disabled) {
        event.preventDefault();
        return;
      }
      if (item.to) {
        event.preventDefault();
        this.koruStore.dispatch(koruStoreTypes.actions.navigateTo, item.to);
        return;
      }
      // execute command
      if (item.command) {
        item.command({ originalEvent: event, item: item });
        event.preventDefault();
      }
      if (item.items) {
        event.preventDefault();
      }
      if (this.root) {
        this.$emit("root-menuitem-click", {
          originalEvent: event,
        });
      }
      if (item.items) {
        this.activeIndex = index === this.activeIndex ? undefined : index;
      }
      this.$emit("menuitem-click", {
        originalEvent: event,
        item: item,
      });
    },
    onMenuItemMouseEnter(index: number): void {
      if (this.root && this.menuActive && this.layoutMode === "slim" && !this.isMobile()) {
        this.activeIndex = index;
      }
    },
    visible(item: Record<string, unknown>): boolean {
      return typeof item.visible === "function" ? item.visible() : item.visible !== false;
    },
    isMobile(): boolean {
      return window.innerWidth <= 991;
    },
    isSlim(): boolean {
      return this.layoutMode === "slim";
    },
  },
});
