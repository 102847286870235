import { BlockInterface } from "@/features/modules/block/models/Block.interface";
import { BlockModelInterface } from "@/features/modules/block/models/BlockModel.interface";
import { koruDb } from "@/core/modules/database";

import { hasDocumentChanged, setDocumentFields } from "@/core/modules/helpers";

export const updateDocument = async (block: BlockInterface): Promise<void> => {
  try {
    const oldBlock: BlockInterface = await koruDb.getModule<BlockModelInterface>("blocks").getDocument(block.id);
    if (hasDocumentChanged(block, oldBlock)) throw new Error("sync");

    setDocumentFields(block.id, block);
    await koruDb.getModule<BlockModelInterface>("blocks").updateDocument(block, true);
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
};
