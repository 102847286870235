import { NotificationInterface } from "@/core/modules/notification/models/Notification.interface";
import { NotificationModelInterface } from "@/core/modules/notification/models/NotificationModel.interface";

export const markNotificationAsDeleted = async (
  notificationModel: NotificationModelInterface,
  notificationId: string,
  userId: string
): Promise<void> => {
  try {
    const notification: NotificationInterface = await notificationModel.getDocument(notificationId);

    const readIndex = notification.unread.indexOf(userId);
    if (readIndex >= 0) {
      notification.unread.splice(readIndex, 1);
    }

    const deleteIndex = notification.owners.indexOf(userId);
    if (deleteIndex < 0) {
      throw new Error(`User #${userId} not found in notification #${notificationId}`);
    }
    notification.owners.splice(deleteIndex, 1);

    if (notification.owners.length == 0) {
      await notificationModel.deleteDocument(notification, false);
    } else {
      await notificationModel.updateDocument(notification, false);
    }
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
};
