import { ActionTree } from "vuex";

import { EmptyStateInterface } from "@/core/modules/store/models/EmptyState.interface";
import { koruDb } from "@/core/modules/database";
import { KoruStateInterface } from "@/core/modules/store/models/KoruState.interface";
import { MailMessageInterface } from "../models/MailMessage.interface";
import { MailMessageModelInterface } from "../models/MailMessageModel.interface";
import { mailMessageStoreTypes } from "./types";

import { setDocumentFields } from "@/core/modules/helpers";

export const actions: ActionTree<EmptyStateInterface, KoruStateInterface> = {
  async [mailMessageStoreTypes.actions.createMailMessage](context, mailMessage: MailMessageInterface): Promise<void> {
    try {
      setDocumentFields(mailMessage.id, mailMessage);
      await koruDb.getModule<MailMessageModelInterface>("mailMessages").createDocument(mailMessage, true);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
};
