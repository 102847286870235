export const en = {
  map: {
    code: "Codice",
    general: "Generale",
    map: "Mappa",
    maps: "Mappe",
    name: "Nome",
    nameError: "Inserire un nome valido",
    styles: {
      aubergine: "Melanzana",
      dark: "Scuro",
      night: "Notte",
      retro: "Retro",
      silver: "Argento",
      standard: "Standard",
    },
    variable: "Variabile",
  },
  navigation: {
    map: "Mappe",
  },
  roleModules: {
    map: "Mappe",
  },
};
