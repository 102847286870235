import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "col-9" }
const _hoisted_3 = {
  key: 0,
  class: "mb-1 pb-0"
}
const _hoisted_4 = {
  key: 1,
  class: "mt-0 mb-2",
  style: {"font-size":"larger"}
}
const _hoisted_5 = {
  key: 0,
  class: "col-3 mt-2 text-right"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.classes)
  }, [
    _renderSlot(_ctx.$slots, "title", {}, () => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.title != undefined)
            ? (_openBlock(), _createElementBlock("h3", _hoisted_3, _toDisplayString(_ctx.title), 1))
            : _createCommentVNode("", true),
          (_ctx.subtitle != undefined)
            ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.subtitle), 1))
            : _createCommentVNode("", true)
        ]),
        (_ctx.hasBackButton)
          ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
              _createVNode(_component_Button, {
                label: _ctx.t('buttons.back'),
                icon: "pi pi-arrow-left",
                class: "p-button-outlined p-button-primary",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goBack()))
              }, null, 8, ["label"]),
              (_ctx.hasEditRoute)
                ? (_openBlock(), _createBlock(_component_Button, {
                    key: 0,
                    label: _ctx.t('buttons.edit'),
                    icon: "pi pi-pencil",
                    class: "p-button-outlined p-button-help ml-4",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.goToEdit()))
                  }, null, 8, ["label"]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}