import { ActionTree } from "vuex";

import { changelogStoreTypes } from "./types";
import { EmptyStateInterface } from "@/core/modules/store/models/EmptyState.interface";
import { KoruStateInterface } from "@/core/modules/store/models/KoruState.interface";
import { ReleaseInterface } from "../models/Release.interface";

import { createDocument, deleteDocument, readDocument, readDocuments, readPublicReleases, sendNotification, updateDocument } from "./actions/index";

export const actions: ActionTree<EmptyStateInterface, KoruStateInterface> = {
  // read documents
  async [changelogStoreTypes.actions.getPublicReleases](): Promise<ReleaseInterface[]> {
    return await readPublicReleases();
  },
  async [changelogStoreTypes.actions.getReleases](): Promise<ReleaseInterface[]> {
    return await readDocuments();
  },

  // read document
  async [changelogStoreTypes.actions.getRelease](context, releaseId: string): Promise<ReleaseInterface> {
    return await readDocument(releaseId);
  },

  // create document
  async [changelogStoreTypes.actions.createRelease](context, release: ReleaseInterface): Promise<string> {
    return await createDocument(release);
  },

  // update document
  async [changelogStoreTypes.actions.updateRelease](context, release: ReleaseInterface): Promise<void> {
    return await updateDocument(release);
  },

  // delete document
  async [changelogStoreTypes.actions.deleteRelease](context, release: ReleaseInterface): Promise<void> {
    return await deleteDocument(release);
  },

  // send notification
  async [changelogStoreTypes.actions.sendNotification](context, release: ReleaseInterface): Promise<void> {
    return await sendNotification(release);
  },
};
