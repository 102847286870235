import { registerStoreModule } from "@/core/modules/helpers";

import { blockStore } from "@/features/modules/block/store";
import { mapPointStore } from "@/features/modules/map-point/store";
import { mapStore } from "@/features/modules/map/store";
import { pageStore } from "@/features/modules/page/store";
import { sectionStore } from "@/features/modules/section/store";
import { settingStore } from "@/features/modules/setting/store";
import { templateStore } from "@/features/modules/template/store";
import { variableStore } from "@/features/modules/variable/store";
import { webRenderStore } from "@/features/modules/web-render/store";

export const registerFeaturesStoreModules = () => {
  registerStoreModule(blockStore);
  registerStoreModule(mapStore);
  registerStoreModule(mapPointStore);
  registerStoreModule(pageStore);
  registerStoreModule(sectionStore);
  registerStoreModule(settingStore);
  registerStoreModule(templateStore);
  registerStoreModule(variableStore);
  registerStoreModule(webRenderStore);
};
