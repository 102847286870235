export const en = {
  section: {
    add: "Aggiungi",
    editHtml: "Modifica HTML",
    editImage: "Modifica immagine",
    editText: "Modifica testo",
    reorder: "Ordina",
    sections: "Sezioni",
    type: "Tipo",
    types: {
      html: "HTML",
      image: "Immagine",
      text: "Testo",
    },
    uploadImage: "Carica immagine",
    value: "Contenuto",
    width: "Larghezza",
    widths: {
      1: "1/12",
      2: "2/12",
      3: "3/12",
      4: "4/12",
      5: "5/12",
      6: "6/12",
      7: "7/12",
      8: "8/12",
      9: "9/12",
      10: "10/12",
      11: "11/12",
      12: "Massima",
    },
  },
  navigation: {
    section: "Sezioni",
  },
  roleModules: {
    section: "Sezioni",
  },
};
