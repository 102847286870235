import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import { LogInterface, logFromFirestore, logToFirestore } from "@/core/modules/log/models/Log.interface";
import { LogModelInterface } from "../LogModel.interface";

import {
  createDocumentHelper,
  createFirestoreConverter,
  deleteDocumentHelper,
  getCollectionReference,
  getDocumentHelper,
} from "@/core/modules/helpers";

export const logModel: LogModelInterface = {
  collectionName: "logs",
  documentFromFirestore: logFromFirestore,
  documentToFirestore: logToFirestore,

  async getDocuments(): Promise<LogInterface[]> {
    return [];
  },
  async getDocument(documentId: string): Promise<LogInterface> {
    return await getDocumentHelper<LogInterface>(documentId, this.collectionName, this.documentFromFirestore, this.documentToFirestore);
  },
  async createDocument(document: LogInterface, logAction: boolean): Promise<string> {
    return await createDocumentHelper<LogInterface>(document, this.collectionName, this.documentFromFirestore, this.documentToFirestore, logAction);
  },
  async updateDocument(): Promise<void> {
    return Promise.resolve();
  },
  async deleteDocument(document: LogInterface, logAction: boolean): Promise<void> {
    await deleteDocumentHelper(document, this.collectionName, logAction);
  },
  async searchDocuments(): Promise<LogInterface[]> {
    return Promise.resolve([]);
  },

  async getLogsByRange(startDate: Date, endDate: Date): Promise<LogInterface[]> {
    try {
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 59, 999);

      const snapshot: firebase.firestore.QuerySnapshot<LogInterface> = await getCollectionReference(this.collectionName)
        .withConverter(createFirestoreConverter(this.documentFromFirestore, this.documentToFirestore))
        .where("createdAt", ">=", firebase.firestore.Timestamp.fromDate(startDate))
        .where("createdAt", "<=", firebase.firestore.Timestamp.fromDate(endDate))
        .orderBy("createdAt", "desc")
        .get();

      if (snapshot == undefined || snapshot.empty) return [];

      return snapshot.docs.map((doc) => doc.data());
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
};
