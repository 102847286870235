import firebase from "firebase/compat/app";
import "firebase/compat/storage";

import { firebaseStorage } from "@/core/plugins/firebase";
import { StorageInterface } from "../models/Storage.interface";

const storageRef = firebaseStorage.ref();

export class FirebaseStorageModel implements StorageInterface {
  async getBase64(name: string): Promise<string> {
    try {
      const url: string = await storageRef.child(name).getDownloadURL();
      const blob = await fetchAsBlob(url);
      const doubleBase64EncodedFile: string = (await convertBlobToBase64(blob)) as string;
      const doubleEncodedBase64String = doubleBase64EncodedFile.split(",")[1];
      return doubleEncodedBase64String;
      // return Buffer.from(doubleEncodedBase64String, "base64").toString();
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  }

  upload(name: string, file: File, callback: (progress: number) => void): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const uploadTask: firebase.storage.UploadTask = storageRef.child(name).put(file);

      uploadTask.on(
        "state_changed",
        (snapshot: firebase.storage.UploadTaskSnapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          callback(Math.floor(progress));
        },
        (error: firebase.storage.FirebaseStorageError) => {
          // Handle unsuccessful uploads
          console.log(error);
          reject();
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadUrl: string) => {
            console.log("File available at", downloadUrl);
            resolve(downloadUrl);
          });
        }
      );
    });
  }

  async delete(name: string): Promise<void> {
    try {
      storageRef.child(name).delete();
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  }
}

const fetchAsBlob = (url: string) => fetch(url).then((response) => response.blob());

const convertBlobToBase64 = (blob: Blob): Promise<unknown> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });
