import { createStoreModule } from "@/core/modules/helpers";
import { EmptyStateInterface } from "@/core/modules/store/models/EmptyState.interface";
import { KoruStoreModuleInterface } from "@/core/modules/store/models/KoruStoreModule.interface";

import { actions } from "./actions";

export const changelogStore: KoruStoreModuleInterface<EmptyStateInterface> = createStoreModule("changelogState", undefined, undefined, actions);

export const useChangelogStore = (): KoruStoreModuleInterface<EmptyStateInterface> => {
  return changelogStore;
};
