import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

export const createDocument = async (): Promise<void> => {
  try {
    const createBackup = firebase.functions().httpsCallable("functionsFirestoreBackupCreate");
    const result = await createBackup();
    console.log(result);
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
};
