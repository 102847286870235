import { ActionTree } from "vuex";

import { DummyStateInterface } from "../models/DummyState.interface";
import { dummyStoreTypes } from "./types";
import { KoruStateInterface } from "@/core/modules/store/models/KoruState.interface";

import { performModulesActions } from "./actions/index";

export const actions: ActionTree<DummyStateInterface, KoruStateInterface> = {
  // perform modules actions
  async [dummyStoreTypes.actions.performModulesActions](context, payload: Record<string, unknown>): Promise<void> {
    await performModulesActions(context, payload);
  },
};
