import { DocumentInterface } from "@/core/modules/database/models/Document.interface";
import { koruBatch } from "@/core/modules/batch";
import { koruDb } from "@/core/modules/database";
import { SearchModuleInterface } from "@/core/modules/search/models/SearchModule.interface";
import { UserInterface } from "../models/User.interface";
import { UserModelInterface } from "../models/UserModel.interface";
import { userStore } from "../store";
import { userStoreTypes } from "../store/types";

import { getCollectionReference } from "@/core/modules/helpers";
import { setSearchKeys } from "../helpers";

export const userSearch: SearchModuleInterface = {
  collectionName: "users",
  format: (doc: DocumentInterface) => doc.fullName as string,
  getLink: (doc: DocumentInterface) => `/users/${doc.id}/edit`,
  icon: "user",
  localeModule: "user",
  roleModule: "user",
  search: (text: string) => koruDb.getModule<UserModelInterface>("users").searchDocuments(text),
  setSearchKeys: processSearchKeys,
  title: "user.user",
};

async function processSearchKeys(): Promise<void> {
  try {
    const users: UserInterface[] = (await userStore.action(userStoreTypes.actions.getUsers)) as UserInterface[];
    for (const user of users) {
      const searchKeys: string[] = setSearchKeys(user);
      koruBatch.update(getCollectionReference("users").doc(user.id), { searchKeys: searchKeys });
    }

    await koruBatch.commit();
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
}
