import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import { ActionContext } from "vuex";

import { DummyModuleInterface } from "@/core/modules/dummy/models/DummyModule.interface";
import { DummyStateInterface } from "@/core/modules/dummy/models/DummyState.interface";
import { dummyStoreTypes } from "../types";
import { koruBatch } from "@/core/modules/batch";
import { koruDummy } from "@/core/modules/dummy";
import { KoruStateInterface } from "@/core/modules/store/models/KoruState.interface";

import { getCollectionReference } from "@/core/modules/helpers";

export const performModulesActions = async (
  context: ActionContext<DummyStateInterface, KoruStateInterface>,
  payload: Record<string, unknown>
): Promise<void> => {
  try {
    const selectedCollections: string[] = payload.selectedCollections as string[];
    const deleteBefore: boolean = payload.deleteBefore as boolean;
    const uploadedData: string | undefined = await context.getters[dummyStoreTypes.getters.getUploadedData];

    if (selectedCollections == undefined || deleteBefore == undefined) throw new Error("invalid parameters");

    const selectedModules: DummyModuleInterface[] = koruDummy.modules.filter((module) => selectedCollections.includes(module.collectionName));

    for (const dummyModule of selectedModules) {
      if (deleteBefore) {
        const snapshot: firebase.firestore.QuerySnapshot = await getCollectionReference(dummyModule.collectionName).get();
        if (snapshot != undefined && !snapshot.empty) {
          for (const doc of snapshot.docs) koruBatch.delete(doc.ref);
        }
      }
      await dummyModule.action(uploadedData);

      await koruBatch.commit();

      await dummyModule.additionalAction();
    }
  } catch (error: unknown) {
    context.commit(dummyStoreTypes.mutations.resetUploadedData);
    throw new Error((error as Error).message);
  } finally {
    context.commit(dummyStoreTypes.mutations.resetUploadedData);
  }
};
