import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "p-fluid formgrid grid" }
const _hoisted_2 = { class: "field col-9" }
const _hoisted_3 = { class: "mb-1 pb-0" }
const _hoisted_4 = {
  class: "mt-0 mb-2",
  style: {"font-size":"larger"}
}
const _hoisted_5 = { class: "field col-3" }
const _hoisted_6 = { class: "p-fluid formgrid grid" }
const _hoisted_7 = { class: "col-12" }
const _hoisted_8 = { class: "col-12 lg:col-6" }
const _hoisted_9 = {
  class: "col-12 lg:col-6",
  style: {"text-align":"right"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Divider = _resolveComponent("Divider")!
  const _component_Button = _resolveComponent("Button")!
  const _component_KoruContainer = _resolveComponent("KoruContainer")!

  return (_openBlock(), _createBlock(_component_KoruContainer, null, {
    title: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h3", _hoisted_3, [
            _renderSlot(_ctx.$slots, "title")
          ]),
          _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.renderSubtitle()), 1)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _renderSlot(_ctx.$slots, "topRight")
        ])
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("form", {
        onSubmit: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.handleSubmit()), ["prevent"])),
        onKeydown: _cache[3] || (_cache[3] = ($event: any) => (_ctx.handleKeydown($event)))
      }, [
        _renderSlot(_ctx.$slots, "form"),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_Divider)
          ]),
          _createElementVNode("div", _hoisted_8, [
            (_ctx.props.record?.id != 'new' && _ctx.userCanDelete(_ctx.requiredModule, _ctx.props.record) && _ctx.props.canDelete)
              ? (_openBlock(), _createBlock(_component_Button, {
                  key: 0,
                  label: _ctx.t('buttons.delete'),
                  class: "p-button-danger p-uppercase",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.confirmDelete($event)))
                }, null, 8, ["label"]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_Button, {
              label: _ctx.t('buttons.close'),
              class: "p-button-primary p-uppercase",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.userCanUpdate(_ctx.requiredModule) ? _ctx.confirmClose($event) : _ctx.closeEditor()))
            }, null, 8, ["label"]),
            (_ctx.props.canCreate && ((_ctx.userCanCreate(_ctx.requiredModule) && _ctx.props.record?.id == 'new') || _ctx.userCanUpdate(_ctx.requiredModule, _ctx.props.record)))
              ? (_openBlock(), _createBlock(_component_Button, {
                  key: 0,
                  label: _ctx.t('buttons.save'),
                  type: "submit",
                  class: "p-button-success p-uppercase ml-4"
                }, null, 8, ["label"]))
              : _createCommentVNode("", true)
          ])
        ])
      ], 32)
    ]),
    _: 3
  }))
}