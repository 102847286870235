import { FeedbackInterface, feedbackFromFirestore, feedbackToFirestore, createNewFeedback } from "@/core/modules/feedback/models/Feedback.interface";
import { FeedbackModelInterface } from "../FeedbackModel.interface";

import { createDocumentHelper } from "@/core/modules/helpers";

export const feedbackModel: FeedbackModelInterface = {
  collectionName: "feedbacks",
  documentFromFirestore: feedbackFromFirestore,
  documentToFirestore: feedbackToFirestore,

  async getDocuments(): Promise<FeedbackInterface[]> {
    return [];
  },
  async getDocument(): Promise<FeedbackInterface> {
    return Promise.resolve(createNewFeedback());
  },
  async createDocument(document: FeedbackInterface, logAction: boolean): Promise<string> {
    return await createDocumentHelper<FeedbackInterface>(
      document,
      this.collectionName,
      this.documentFromFirestore,
      this.documentToFirestore,
      logAction
    );
  },
  async updateDocument(): Promise<void> {
    return Promise.resolve();
  },
  async deleteDocument(): Promise<void> {
    return Promise.resolve();
  },
  async searchDocuments(): Promise<FeedbackInterface[]> {
    return Promise.resolve([]);
  },
};
