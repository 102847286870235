
import { defineComponent, onMounted, Ref, ref } from "vue";
import { useI18n } from "vue-i18n";
import { usePrimeVue } from "primevue/config";

import { authStoreTypes } from "@/core/modules/auth/store/types";
import { LocaleInterface } from "../models/Locale.interface";
import { localeStoreTypes } from "../store/types";
import { useAuthStore } from "@/core/modules/auth/store";
import { useLocaleStore } from "../store";
import { UserInterface } from "@/core/modules/user/models/User.interface";
import { useKoruLocale } from "../";

import { enPrimevue } from "@/core/locales/primevue/en.ts";
import { itPrimevue } from "@/core/locales/primevue/it.ts";

export default defineComponent({
  name: "LocaleSelector",
  setup() {
    const { t } = useKoruLocale();
    const authStore = useAuthStore();
    const localeStore = useLocaleStore();
    const primeVue = usePrimeVue();
    const useLocale = useI18n();

    const locales: LocaleInterface[] = localeStore.getter(localeStoreTypes.getters.getLocales);
    const selectedLocale: Ref<LocaleInterface | undefined> = ref(undefined);

    const selectLocale = (locale: LocaleInterface): void => {
      selectedLocale.value = locale;
      localeStore.action(localeStoreTypes.actions.setSelectedLocale, locale);
      useLocale.locale.value = locale.name;
      if (locale.name == "en") {
        primeVue.config.locale = enPrimevue;
      } else {
        primeVue.config.locale = itPrimevue;
      }
    };

    onMounted(() => {
      const user: UserInterface = authStore.getter(authStoreTypes.getters.getUser);
      for (const locale of locales) {
        if (locale.name == user.detail.locale) {
          selectLocale(locale);
          break;
        }
      }
    });

    return {
      locales,
      localeStore,
      primeVue,
      selectedLocale,
      selectLocale,
      t,
      useLocale,
    };
  },
});
