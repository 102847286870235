import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import { ChangelogModelInterface } from "@/core/modules/changelog/models/ChangelogModel.interface";
import { ReleaseInterface } from "@/core/modules/changelog/models/Release.interface";

import { createFirestoreConverter, getCollectionReference } from "@/core/modules/helpers";

export const getPublicReleases = async (changelogModel: ChangelogModelInterface): Promise<ReleaseInterface[]> => {
  try {
    const snapshot: firebase.firestore.QuerySnapshot<ReleaseInterface> = await getCollectionReference(changelogModel.collectionName)
      .withConverter(createFirestoreConverter(changelogModel.documentFromFirestore, changelogModel.documentToFirestore))
      .where("public", "==", true)
      .orderBy("date", "desc")
      .get();

    if (snapshot == undefined || snapshot.empty) return [];

    return snapshot.docs.map((doc) => doc.data());
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
};
