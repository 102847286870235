import { BlockInterface } from "@/features/modules/block/models/Block.interface";
import { BlockModelInterface } from "@/features/modules/block/models/BlockModel.interface";
import { koruDb } from "@/core/modules/database";

export const readDocument = async (documentId: string): Promise<BlockInterface> => {
  try {
    return await koruDb.getModule<BlockModelInterface>("blocks").getDocument(documentId);
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
};
