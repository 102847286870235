import { createStoreModule } from "@/core/modules/helpers";
import { KoruStoreModuleInterface } from "@/core/modules/store/models/KoruStoreModule.interface";
import { LocaleStateInterface } from "../models/LocaleState.interface";

import { actions } from "./actions";
import { getters } from "./getters";
import { initialState } from "./initial-state";

export const localeStore: KoruStoreModuleInterface<LocaleStateInterface> = createStoreModule("localeState", initialState, getters, actions);

export const useLocaleStore = (): KoruStoreModuleInterface<LocaleStateInterface> => {
  return localeStore;
};
