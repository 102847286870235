import { ActionTree } from "vuex";

import { EmptyStateInterface } from "@/core/modules/store/models/EmptyState.interface";
import { koruDb } from "@/core/modules/database";
import { KoruStateInterface } from "@/core/modules/store/models/KoruState.interface";
import { PageInterface } from "../models/Page.interface";
import { PageModelInterface } from "../models/PageModel.interface";
import { pageStoreTypes } from "./types";
import { webRenderStore } from "@/features/modules/web-render/store";
import { webRenderStoreTypes } from "@/features/modules/web-render/store/types";

import { hasDocumentChanged, setDocumentFields } from "@/core/modules/helpers";
import { setWebDocumentFields } from "@/features/modules/web-document";

export const actions: ActionTree<EmptyStateInterface, KoruStateInterface> = {
  async [pageStoreTypes.actions.getPages](): Promise<PageInterface[]> {
    try {
      return await koruDb.getModule<PageModelInterface>("pages").getDocuments();
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [pageStoreTypes.actions.getPagesByTemplate](context, templateId: string): Promise<PageInterface[]> {
    try {
      return await koruDb.getModule<PageModelInterface>("pages").getPagesByTemplate(templateId);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [pageStoreTypes.actions.getPagesByMapReferenceNumber](context, mapReferenceNumber: number): Promise<PageInterface[]> {
    try {
      return await koruDb.getModule<PageModelInterface>("pages").getPagesByMapReferenceNumber(mapReferenceNumber);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [pageStoreTypes.actions.getPage](context, pageId: string): Promise<PageInterface> {
    try {
      return await koruDb.getModule<PageModelInterface>("pages").getDocument(pageId);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [pageStoreTypes.actions.createPage](context, page: PageInterface): Promise<string> {
    try {
      await setWebDocumentFields(page);
      setDocumentFields(page.id, page);
      page.id = await koruDb.getModule<PageModelInterface>("pages").createDocument(page, true);

      const payload: Record<string, unknown> = { webDocument: page };
      const updatedPage: PageInterface = (await webRenderStore.action(
        webRenderStoreTypes.actions.processWebRenderForWebDocument,
        payload
      )) as PageInterface;

      await koruDb.getModule<PageModelInterface>("pages").updateDocument(updatedPage, false);

      await webRenderStore.action(webRenderStoreTypes.actions.processWebRenderForGeneralUpdate);

      return page.id;
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [pageStoreTypes.actions.updatePage](context, page: PageInterface): Promise<void> {
    try {
      const oldPage: PageInterface = await koruDb.getModule<PageModelInterface>("pages").getDocument(page.id);
      if (hasDocumentChanged(page, oldPage)) throw new Error("sync");

      await setWebDocumentFields(page);
      setDocumentFields(page.id, page);
      await koruDb.getModule<PageModelInterface>("pages").updateDocument(page, true);

      const payload: Record<string, unknown> = { webDocument: page };
      const updatedPage: PageInterface = (await webRenderStore.action(
        webRenderStoreTypes.actions.processWebRenderForWebDocument,
        payload
      )) as PageInterface;

      await koruDb.getModule<PageModelInterface>("pages").updateDocument(updatedPage, false);

      await webRenderStore.action(webRenderStoreTypes.actions.processWebRenderForGeneralUpdate);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [pageStoreTypes.actions.deletePage](context, page: PageInterface): Promise<void> {
    try {
      await koruDb.getModule<PageModelInterface>("pages").deleteDocument(page, true);

      const payload: Record<string, unknown> = { webDocument: page };
      await webRenderStore.action(webRenderStoreTypes.actions.deleteWebRenderForWebDocument, payload);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
};
