import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TabView = _resolveComponent("TabView")!
  const _component_KoruContainer = _resolveComponent("KoruContainer")!

  return (_openBlock(), _createBlock(_component_KoruContainer, {
    title: _ctx.titleRef,
    backButton: _ctx.hasBackButton,
    editRoute: _ctx.editRouteProp
  }, {
    default: _withCtx(() => [
      _createVNode(_component_TabView, {
        activeIndex: _ctx.sectionIndex,
        "onUpdate:activeIndex": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.sectionIndex) = $event)),
        onTabChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.sectionChanged($event)))
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "content")
        ]),
        _: 3
      }, 8, ["activeIndex"])
    ]),
    _: 3
  }, 8, ["title", "backButton", "editRoute"]))
}