import { koruAuth } from "@/core/modules/auth";
import { koruConfig } from "@/core/modules/config";
import { koruDb } from "@/core/modules/database";
import { NotificationInterface, createNewNotification } from "@/core/modules/notification/models/Notification.interface";
import { NotificationModelInterface } from "@/core/modules/notification/models/NotificationModel.interface";
import { UserInterface } from "@/core/modules/user/models/User.interface";
import { UserModelInterface } from "@/core/modules/user/models/UserModel.interface";

import { setDocumentFields } from "@/core/modules/helpers";
import { setFullNames, setSearchKeys } from "@/core/modules/user/helpers";

export const createDocument = async (user: UserInterface): Promise<string> => {
  try {
    setFullNames(user);

    user.searchKeys = setSearchKeys(user);

    setDocumentFields(user.id, user);
    user.id = await koruAuth.registerUser(user);
    const newUserId: string = await koruDb.getModule<UserModelInterface>("users").createDocument(user, true);

    const userNotification: NotificationInterface = createNewNotification();
    userNotification.type = "userCreated";
    userNotification.reference = user.id;
    userNotification.message = user.fullName;
    userNotification.owners = await koruDb
      .getModule<UserModelInterface>("users")
      .getUsersIdsByModuleAndRight(koruConfig.notification.types.userCreated.requiredModule, koruConfig.notification.types.userCreated.requiredRight);
    userNotification.unread = userNotification.owners;
    await koruDb.getModule<NotificationModelInterface>("notifications").createDocument(userNotification, false);

    return newUserId;
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
};
