import { GetterTree } from "vuex";

import { KoruStateInterface } from "@/core/modules/store/models/KoruState.interface";
import { DummyStateInterface } from "../models/DummyState.interface";
import { dummyStoreTypes } from "./types";

export const getters: GetterTree<DummyStateInterface, KoruStateInterface> = {
  [dummyStoreTypes.getters.getUploadedData]: (state: DummyStateInterface): string | undefined => {
    return state.uploadedData;
  },
};
