import { exportBackup } from "@/core/plugins/import-export";

import { CollectionInterface } from "@/core/modules/config/models/Collection.interface";
import { WorkbenchModuleInterface } from "../models/WorkbenchModule.interface";

export const exportData: WorkbenchModuleInterface = {
  name: "exportData",
  label: "workbench.exportData",
  action: async (selectedCollections: CollectionInterface[]): Promise<void> => {
    return await performAction(selectedCollections);
  },
};

async function performAction(selectedCollections: CollectionInterface[]): Promise<void> {
  try {
    if (selectedCollections.length <= 0) {
      console.log("No collections");
      return;
    }
    const data: unknown = await exportBackup(selectedCollections);
    const jsonString: string = JSON.stringify(data, undefined, 2);

    const link = document.createElement("a");
    link.download = "collections.json";
    const blob = new Blob([jsonString], { type: "application/json" });
    link.href = window.URL.createObjectURL(blob);
    link.click();
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
}
