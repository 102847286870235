import { ActionTree } from "vuex";

import { EmptyStateInterface } from "@/core/modules/store/models/EmptyState.interface";
import { KoruStateInterface } from "@/core/modules/store/models/KoruState.interface";
import { RoleInterface } from "../models/Role.interface";
import { roleStoreTypes } from "./types";

import { createDocument, deleteDocument, fromMatrixToValues, fromValuesToMatrix, readDocument, readDocuments, updateDocument } from "./actions/index";

export const actions: ActionTree<EmptyStateInterface, KoruStateInterface> = {
  // read documents
  async [roleStoreTypes.actions.getRoles](): Promise<RoleInterface[]> {
    return await readDocuments();
  },

  // read document
  async [roleStoreTypes.actions.getRole](context, roleId: string): Promise<RoleInterface> {
    return await readDocument(roleId);
  },

  // create document
  async [roleStoreTypes.actions.createRole](context, role: RoleInterface): Promise<string> {
    return await createDocument(role);
  },

  // update document
  async [roleStoreTypes.actions.updateRole](context, role: RoleInterface): Promise<void> {
    await updateDocument(role);
  },

  // delete document
  async [roleStoreTypes.actions.deleteRole](context, role: RoleInterface): Promise<void> {
    await deleteDocument(role);
  },

  // matrix converters
  [roleStoreTypes.actions.fromMatrixToValues](context, payload: Record<string, unknown>): RoleInterface {
    return fromMatrixToValues(payload);
  },
  [roleStoreTypes.actions.fromValuesToMatrix](context, payload: Record<string, unknown>): Record<string, unknown>[] {
    return fromValuesToMatrix(payload);
  },
};
