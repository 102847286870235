import { ConfigInterface } from "./models/Config.interface";

import configLive from "@/data/app-config-live.json";
import configMock from "@/data/app-config-mock.json";

let env = "mock";
if (process.env && process.env.VUE_APP_CONFIG) {
  env = process.env.VUE_APP_CONFIG.trim().toLowerCase();
}

const configsMap: Map<string, ConfigInterface> = new Map<string, ConfigInterface>([
  ["live", configLive],
  ["mock", configMock],
]);

if (!configsMap.has(env)) {
  throw Error(`Could not find config for VUE_APP_CONFIG key "${env}"`);
}

export const koruConfig: ConfigInterface = configsMap.get(env) as ConfigInterface;

export const useKoruConfig = (): ConfigInterface => {
  return koruConfig;
};
