import { RouteRecordRaw } from "vue-router";

export const changelogRoutes: Array<RouteRecordRaw> = [
  {
    path: "/changelog",
    name: "ChangelogList",
    component: () => import("../views/ChangelogList.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "changelog",
      requiredRights: ["readAll", "readOwn"],
    },
  },
  {
    path: "/changelog/:id/edit",
    name: "ChangelogEdit",
    component: () => import("../views/ChangelogEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "changelog",
      requiredRights: ["create", "updateAll", "updateOwn"],
    },
  },
  {
    path: "/changelog-view",
    name: "ChangelogView",
    component: () => import("../views/ChangelogView.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "",
      requiredRights: [],
    },
  },
];
