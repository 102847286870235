import { koruConfig } from "@/core/modules/config";
import { koruDb } from "@/core/modules/database";
import { NotificationInterface, createNewNotification } from "@/core/modules/notification/models/Notification.interface";
import { notificationStore } from "@/core/modules/notification/store";
import { notificationStoreTypes } from "@/core/modules/notification/store/types";
import { ReleaseInterface } from "@/core/modules/changelog/models/Release.interface";
import { UserModelInterface } from "@/core/modules/user/models/UserModel.interface";

export const sendNotification = async (release: ReleaseInterface): Promise<void> => {
  try {
    const notification: NotificationInterface = createNewNotification();
    notification.type = "releaseCreated";
    notification.reference = release.id;
    notification.message = release.version;
    notification.owners = await koruDb
      .getModule<UserModelInterface>("users")
      .getUsersIdsByModuleAndRight(
        koruConfig.notification.types.releaseCreated.requiredModule,
        koruConfig.notification.types.releaseCreated.requiredRight
      );
    notification.unread = notification.owners;
    await notificationStore.action(notificationStoreTypes.actions.createNotification, notification);
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
};
