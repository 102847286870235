export const dummyStoreTypes = {
  getters: {
    getUploadedData: "GET_UPLOADED_DATA",
  },
  actions: {
    performModulesActions: "PERFORM_MODULES_ACTIONS",
  },
  mutations: {
    resetUploadedData: "RESET_UPLOADED_DATA",
    setUploadedData: "SET_UPLOADED_DATA",
  },
};
