import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import { MapPointInterface, mapPointFromFirestore, mapPointToFirestore } from "@/features/modules/map-point/models/MapPoint.interface";
import { MapPointModelInterface } from "../MapPointModel.interface";

import {
  createDocumentHelper,
  createFirestoreConverter,
  deleteDocumentHelper,
  getCollectionReference,
  getDocumentHelper,
  getDocumentsHelper,
  updateDocumentHelper,
} from "@/core/modules/helpers";

export const mapPointModel: MapPointModelInterface = {
  collectionName: "mapPoints",
  documentFromFirestore: mapPointFromFirestore,
  documentToFirestore: mapPointToFirestore,

  async getDocuments(): Promise<MapPointInterface[]> {
    return await getDocumentsHelper<MapPointInterface>(
      this.collectionName,
      "name",
      "asc",
      0,
      0,
      this.documentFromFirestore,
      this.documentToFirestore
    );
  },
  async getDocument(documentId: string): Promise<MapPointInterface> {
    return await getDocumentHelper<MapPointInterface>(documentId, this.collectionName, this.documentFromFirestore, this.documentToFirestore);
  },
  async createDocument(document: MapPointInterface, logAction: boolean): Promise<string> {
    return await createDocumentHelper<MapPointInterface>(
      document,
      this.collectionName,
      this.documentFromFirestore,
      this.documentToFirestore,
      logAction
    );
  },
  async updateDocument(document: MapPointInterface, logAction: boolean): Promise<void> {
    await updateDocumentHelper<MapPointInterface>(document, this.collectionName, this.documentFromFirestore, this.documentToFirestore, logAction);
  },
  async deleteDocument(document: MapPointInterface, logAction: boolean): Promise<void> {
    await deleteDocumentHelper(document, this.collectionName, logAction);
  },
  async searchDocuments(): Promise<MapPointInterface[]> {
    return Promise.resolve([]);
  },

  /* custom methods */

  async getMapPointsByMap(mapId: string): Promise<MapPointInterface[]> {
    try {
      const snapshot: firebase.firestore.QuerySnapshot<MapPointInterface> = await getCollectionReference(this.collectionName)
        .withConverter(createFirestoreConverter(this.documentFromFirestore, this.documentToFirestore))
        .where("map.id", "==", mapId)
        .get();

      if (snapshot.empty) {
        return [];
      }

      const data: MapPointInterface[] = [];
      snapshot.docs.map((doc) => {
        data.push(doc.data());
      });
      return data;
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
};
