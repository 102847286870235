import { koruDb } from "@/core/modules/database";
import { NotificationInterface } from "@/core/modules/notification/models/Notification.interface";
import { NotificationModelInterface } from "@/core/modules/notification/models/NotificationModel.interface";

export const createDocument = async (notification: NotificationInterface): Promise<string> => {
  try {
    return await koruDb.getModule<NotificationModelInterface>("notifications").createDocument(notification, false);
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
};
