import { BlockInterface } from "@/features/modules/block/models/Block.interface";
import { koruBatch } from "@/core/modules/batch";

import { getCollectionReference } from "@/core/modules/helpers";

export const reorderBlocks = async (blocks: BlockInterface[]): Promise<void> => {
  try {
    let order = 1;
    for (const block of blocks) {
      koruBatch.update(getCollectionReference("blocks").doc(block.id), { order: order });
      order++;
    }
    await koruBatch.commit();
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
};
