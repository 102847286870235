import { koruConfig } from "@/core/modules/config";
import { RoleInterface } from "@/core/modules/role/models/Role.interface";

import { getObjectValueWithField } from "@/core/modules/helpers";

export const fromValuesToMatrix = (payload: Record<string, unknown>): Record<string, unknown>[] => {
  try {
    const role: RoleInterface = payload.role as RoleInterface;
    const t: (entry: string) => string = payload.t as (entry: string) => string;

    if (t == undefined) throw new Error("missing data");

    const roleModules = koruConfig.authentication.roleModules;
    const matrix: Record<string, unknown>[] = [];

    for (const roleModule of roleModules) {
      const roleObject: Record<string, unknown> = { roleModule: roleModule };
      if (role == undefined) {
        for (const right of koruConfig.authentication.rights) roleObject[right] = false;
      } else {
        for (const right of koruConfig.authentication.rights) {
          roleObject[right] = (getObjectValueWithField(role, right) as string[]).includes(roleModule) ? true : false;
        }
      }
      matrix.push(roleObject);
    }

    matrix.sort((a, b) =>
      t(`roleModules.${a.roleModule}`) > t(`roleModules.${b.roleModule}`)
        ? 1
        : t(`roleModules.${b.roleModule}`) > t(`roleModules.${a.roleModule}`)
        ? -1
        : 0
    );

    return matrix;
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
};
