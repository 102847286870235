import { DatabaseModuleInterface } from "@/core/modules/database/models/DatabaseModule.interface";
import { koruDb } from "@/core/modules/database";
import { RoleInterface } from "@/core/modules/role/models/Role.interface";

import { runBeforeDeleteFunction } from "@/core/modules/helpers";

export const deleteDocument = async (role: RoleInterface): Promise<void> => {
  try {
    await runBeforeDeleteFunction("functionsCoreRoleBeforeDelete", role.id);

    await koruDb.getModule<DatabaseModuleInterface<RoleInterface>>("roles").deleteDocument(role, true);
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
};
