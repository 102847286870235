import { authStore } from "@/core/modules/auth/store";
import { authStoreTypes } from "@/core/modules/auth/store/types";
import { createLinkedUserFromUser } from "../user/models/LinkedUser.interface";
import { DatabaseModuleInterface } from "@/core/modules/database/models/DatabaseModule.interface";
import { koruDb } from "@/core/modules/database";
import { KoruLogInterface } from "./models/KoruLog.interface";
import { LogInterface, createNewLog } from "./models/Log.interface";
import { UserInterface } from "@/core/modules/user/models/User.interface";

import { setDocumentFields } from "../helpers";

export const koruLog: KoruLogInterface = {
  logError: (message: string) => saveLog("error", message),
  logInfo: (message: string) => saveLog("info", message),
  logWarning: (message: string) => saveLog("warning", message),
};

export const useKoruLog = (): KoruLogInterface => {
  return koruLog;
};

async function saveLog(type: string, message: string): Promise<void> {
  try {
    const user: UserInterface = authStore.getter(authStoreTypes.getters.getUser);

    const log: LogInterface = createNewLog();
    log.user = createLinkedUserFromUser(user);

    log.type = type;
    log.message = message;
    setDocumentFields(log.id, log);

    await koruDb.getModule<DatabaseModuleInterface<LogInterface>>("logs").createDocument(log, false);
  } catch (error: unknown) {
    console.log((error as Error).message);
  }
}
