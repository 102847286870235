import { RouteRecordRaw } from "vue-router";

export const blockRoutes: Array<RouteRecordRaw> = [
  {
    path: "/blocks",
    name: "BlockList",
    component: () => import("../views/BlockList.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "block",
      requiredRights: ["readAll", "readOwn"],
    },
  },
  {
    path: "/blocks/:id/edit",
    name: "BlockEdit",
    component: () => import("../views/BlockEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "block",
      requiredRights: ["create", "updateAll", "updateOwn"],
    },
  },
];
