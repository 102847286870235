import { createLinkedRoleFromRole } from "@/core/modules/role/models/LinkedRole.interface";
import { DatabaseModuleInterface } from "@/core/modules/database/models/DatabaseModule.interface";
import { DummyModuleInterface } from "@/core/modules/dummy/models/DummyModule.interface";
import { koruBatch } from "@/core/modules/batch";
import { koruDb } from "@/core/modules/database";
import { RoleInterface } from "@/core/modules/role/models/Role.interface";
import { UserInterface, createNewUser, userToFirestore } from "../models/User.interface";

import { getCollectionReference } from "@/core/modules/helpers";

export const userDummy: DummyModuleInterface = {
  collectionName: "users",
  action: async (): Promise<void> => {
    const role: RoleInterface = await koruDb.getModule<DatabaseModuleInterface<RoleInterface>>("roles").getDocument("95Clp88EfEyX5fY0DdNS");

    const user: UserInterface = createNewUser();

    user.firstName = "Marco";
    user.lastName = "Lisatti";
    user.fullName = "Lisatti Marco";
    user.fullNameReversed = "Marco Lisatti";
    user.email = "marco@spinningtop.it";
    user.role = createLinkedRoleFromRole(role);

    const timestamp = new Date();
    user.createdAt = timestamp;
    user.createdBy = "install";
    user.updatedAt = timestamp;
    user.updatedBy = "install";

    koruBatch.set(getCollectionReference("users").doc("XvbvA13USXeMiGOhOmlXEUiI9La2"), userToFirestore(user));
  },
  additionalAction: async (): Promise<void> => Promise.resolve(),
};
