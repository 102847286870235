
import { ComputedRef, computed, defineComponent, Ref, ref } from "vue";
import { useRoute } from "vue-router";

import { koruStoreTypes } from "@/core/modules/store/types";
import { useKoruStore } from "@/core/modules/store";

import { tryOrError } from "@/core/modules/helpers";

export default defineComponent({
  name: "KoruViewer",
  props: {
    backButton: { type: Boolean, default: false },
    currentSection: { type: String, default: "" },
    editRoute: { type: String },
    loadAction: { type: Function },
    sections: { type: Object },
    title: { type: String, default: "" },
  },
  emits: ["update:sectionIndex"],
  setup(props) {
    const koruStore = useKoruStore();
    const route = useRoute();

    const sectionIndex: Ref<number> = ref(0);

    const titleRef: ComputedRef<string> = computed(() => props.title);

    const sectionsRef: ComputedRef<Record<number, string>> = computed(() => props.sections as Record<number, string>);

    const sectionChanged = async (event: Record<string, unknown>) => {
      let routePieces: string[] = route.path.split("/");
      routePieces = routePieces.filter((piece) => piece);

      const sections: string[] = Object.values(sectionsRef.value);
      const newSection: string = encodeSection(event.index as number);

      if (newSection == "info") {
        routePieces.splice(routePieces.length - 1, 1);
      } else {
        if (sections.indexOf(routePieces[routePieces.length - 1]) >= 0) {
          routePieces[routePieces.length - 1] = newSection;
        } else {
          routePieces.push(newSection);
        }
      }

      const newRoute = `/${routePieces.join("/")}`;
      await koruStore.dispatch(koruStoreTypes.actions.navigateUndercoverTo, newRoute);
      koruStore.commit(koruStoreTypes.mutations.loadingStop);
    };

    const selectSection = () => {
      for (const [key, value] of Object.entries(sectionsRef.value)) {
        if (value == props.currentSection && Number(key) > 0) {
          sectionIndex.value = Number(key);
          return;
        }
      }
    };

    const encodeSection = (index: number): string => {
      return sectionsRef.value[index];
    };

    const handleLoad = async () => {
      await tryOrError(
        async () => {
          if (props.loadAction != undefined) await props.loadAction();
        },
        () => {
          selectSection();
          koruStore.commit(koruStoreTypes.mutations.loadingStop);
        }
      );
    };

    handleLoad();

    return {
      editRouteProp: props.editRoute,
      hasBackButton: props.backButton,
      titleProp: props.title,
      sectionIndex,
      sectionChanged,
      titleRef,
    };
  },
});
