import { DatabaseModuleInterface } from "@/core/modules/database/models/DatabaseModule.interface";
import { koruDb } from "@/core/modules/database";
import { RoleInterface } from "@/core/modules/role/models/Role.interface";

export const readDocument = async (documentId: string): Promise<RoleInterface> => {
  try {
    return await koruDb.getModule<DatabaseModuleInterface<RoleInterface>>("roles").getDocument(documentId);
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
};
