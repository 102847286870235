import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import { CollectionInterface } from "@/core/modules/config/models/Collection.interface";

import { getCollectionReference } from "@/core/modules/helpers";
import { IExportOptions } from "./helpers";

export const exportBackup = async (collectionsArray: CollectionInterface[], options?: IExportOptions): Promise<unknown> => {
  const promises: Promise<unknown>[] = [];
  for (const collection of collectionsArray) {
    const collectionName: string = collection.name;
    const subCollections: string[] = collection.subCollections;

    const result = exportCollectionBackup(collectionName, options, subCollections);
    promises.push(result);
  }

  const value = await Promise.all(promises);
  const all = Object.assign({}, ...value);
  return all;
};

export const exportCollectionBackup = async (
  collectionName: string,
  options?: IExportOptions,
  subCollections: string[] = []
): Promise<Record<string, Record<string, unknown>>> => {
  try {
    const data: Record<string, Record<string, unknown>> = {};
    data[collectionName] = {};

    const documents: firebase.firestore.QuerySnapshot<unknown> =
      options?.queryCollection != null
        ? await options.queryCollection(getCollectionReference(collectionName))
        : await getCollectionReference(collectionName).get();

    const docs: firebase.firestore.QueryDocumentSnapshot<unknown>[] =
      options?.docsFromEachCollection != undefined && options?.docsFromEachCollection > 0
        ? documents.docs.slice(0, options?.docsFromEachCollection)
        : documents.docs;

    for (const doc of docs) {
      data[collectionName][doc.id] = doc.data();

      if (subCollections.length > 0) {
        (data[collectionName][doc.id] as Record<string, unknown>)["subCollection"] = {};

        for (const subCol of subCollections) {
          const subColData: Promise<unknown>[] = [];
          const result = exportCollectionBackup(`${collectionName}/${doc.id}/${subCol}`, options, []);
          subColData.push(result);
          const value = await Promise.all(subColData);
          const valueFinal = Object.assign({}, ...value);

          (data[collectionName][doc.id] as Record<string, unknown>)["subCollection"] = {
            ...((data[collectionName][doc.id] as Record<string, unknown>)["subCollection"] as Record<string, unknown>),
            ...valueFinal,
          };
        }
      }
    }

    return data;
  } catch (error) {
    console.error(error);
    throw new Error(error as string);
  }
};
