import { ActionTree } from "vuex";

import { EmptyStateInterface } from "@/core/modules/store/models/EmptyState.interface";
import { FeedbackInterface } from "../models/Feedback.interface";
import { FeedbackModelInterface } from "../models/FeedbackModel.interface";
import { feedbackStoreTypes } from "./types";
import { koruDb } from "@/core/modules/database";
import { KoruStateInterface } from "@/core/modules/store/models/KoruState.interface";

export const actions: ActionTree<EmptyStateInterface, KoruStateInterface> = {
  async [feedbackStoreTypes.actions.createFeedback](context, feedback: FeedbackInterface): Promise<void> {
    try {
      await koruDb.getModule<FeedbackModelInterface>("feedbacks").createDocument(feedback, true);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
};
