export const it = {
  page: {
    code: "Codice",
    homepage: "Homepage",
    metaDescription: "Descrizione META",
    metaKeywords: "Parole chiave META",
    name: "Nome",
    nameError: "Inserisci un nome valido",
    notFound: "Pagina errore",
    page: "Pagina",
    pages: "Pagine",
    publicAlways: "Pubblica",
    publicAt: "Data pubblicazione",
    publicAtPlaceholder: "Sempre pubblica",
    publicWhen: "Pubblica da",
    sections: "Sezioni",
    state: "Stato",
    template: "Modello",
    templateError: "Seleziona un modello",
    title: "Titolo",
    titleError: "Inserisci un titolo valido",
    useDefault: "se vuoto usa valori predefiniti",
  },
  buttons: {
    goToPublic: "Apri link pubblico",
  },
  navigation: {
    page: "Pagine",
  },
  roleModules: {
    page: "Pagine",
  },
};
