export const backupStoreTypes = {
  getters: {
    getUploadedData: "GET_UPLOADED_DATA",
  },
  actions: {
    // read documents
    getBackups: "GET_BACKUPS",

    // create document
    createBackup: "CREATE_BACKUP",

    // delete document
    deleteBackup: "DELETE_BACKUP",

    // restore backup
    restoreBackup: "RESTORE_BACKUP",
  },
  mutations: {
    resetUploadedData: "RESET_UPLOADED_DATA",
    setUploadedData: "SET_UPLOADED_DATA",
  },
};
