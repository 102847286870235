export const en = {
  block: {
    code: "Codice",
    block: "Blocco",
    blocks: "Blocchi",
    general: "Generale",
    name: "Nome",
    nameError: "Inserire un nome valido",
  },
  navigation: {
    block: "Blocchi",
  },
  roleModules: {
    block: "Blocchi",
  },
};
