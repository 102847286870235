import { registerStoreModule } from "@/core/modules/helpers";

import { authStore } from "@/core/modules/auth/store";
import { backupStore } from "@/core/modules/backup/store";
import { changelogStore } from "@/core/modules/changelog/store";
import { dummyStore } from "@/core/modules/dummy/store";
import { feedbackStore } from "@/core/modules/feedback/store";
import { installerStore } from "@/core/modules/installer/store";
import { localeStore } from "@/core/modules/locale/store";
import { logStore } from "@/core/modules/log/store";
import { notificationStore } from "@/core/modules/notification/store";
import { roleStore } from "@/core/modules/role/store";
import { searchStore } from "@/core/modules/search/store";
import { themeStore } from "@/core/modules/theme/store";
import { userStore } from "@/core/modules/user/store";
import { workbenchStore } from "@/core/modules/workbench/store";

export const registerCoreStoreModules = () => {
  registerStoreModule(authStore);
  registerStoreModule(backupStore);
  registerStoreModule(changelogStore);
  registerStoreModule(dummyStore);
  registerStoreModule(feedbackStore);
  registerStoreModule(installerStore);
  registerStoreModule(localeStore);
  registerStoreModule(logStore);
  registerStoreModule(notificationStore);
  registerStoreModule(roleStore);
  registerStoreModule(searchStore);
  registerStoreModule(themeStore);
  registerStoreModule(userStore);
  registerStoreModule(workbenchStore);
};
