import { NotificationInterface, notificationFromFirestore, notificationToFirestore } from "@/core/modules/notification/models/Notification.interface";
import { NotificationModelInterface } from "../NotificationModel.interface";

import { createDocumentHelper, deleteDocumentHelper, getDocumentHelper, getDocumentsHelper, updateDocumentHelper } from "@/core/modules/helpers";

import { getNotificationsByOwner, getUnreadNotifications, markNotificationAsDeleted, markNotificationAsRead } from "./methods";

export const notificationModel: NotificationModelInterface = {
  collectionName: "notifications",
  documentFromFirestore: notificationFromFirestore,
  documentToFirestore: notificationToFirestore,

  async getDocuments(): Promise<NotificationInterface[]> {
    return await getDocumentsHelper<NotificationInterface>(
      this.collectionName,
      "createdAt",
      "desc",
      0,
      0,
      this.documentFromFirestore,
      this.documentToFirestore
    );
  },
  async getDocument(documentId: string): Promise<NotificationInterface> {
    return await getDocumentHelper<NotificationInterface>(documentId, this.collectionName, this.documentFromFirestore, this.documentToFirestore);
  },
  async createDocument(document: NotificationInterface, logAction: boolean): Promise<string> {
    return await createDocumentHelper<NotificationInterface>(
      document,
      this.collectionName,
      this.documentFromFirestore,
      this.documentToFirestore,
      logAction
    );
  },
  async updateDocument(document: NotificationInterface, logAction: boolean): Promise<void> {
    await updateDocumentHelper<NotificationInterface>(document, this.collectionName, this.documentFromFirestore, this.documentToFirestore, logAction);
  },
  async deleteDocument(document: NotificationInterface, logAction: boolean): Promise<void> {
    await deleteDocumentHelper(document, this.collectionName, logAction);
  },
  async searchDocuments(): Promise<NotificationInterface[]> {
    return Promise.resolve([]);
  },

  /* custom methods */

  async getNotificationsByOwner(userId: string): Promise<NotificationInterface[]> {
    return getNotificationsByOwner(this, userId);
  },
  async getUnreadNotifications(userId: string): Promise<NotificationInterface[]> {
    return getUnreadNotifications(this, userId);
  },
  async markNotificationAsDeleted(notificationId: string, userId: string): Promise<void> {
    return markNotificationAsDeleted(this, notificationId, userId);
  },
  async markNotificationAsRead(notificationId: string, userId: string): Promise<void> {
    return markNotificationAsRead(this, notificationId, userId);
  },
};
