import { registerWorkbenchModule } from "@/core/modules/helpers";

import { deleteData, exportData, importData, sendTestMail, setSearchKeys } from "@/core/modules/workbench/modules";

export const registerCoreWorkbenchModules = () => {
  registerWorkbenchModule(deleteData);
  registerWorkbenchModule(exportData);
  registerWorkbenchModule(importData);
  registerWorkbenchModule(sendTestMail);
  registerWorkbenchModule(setSearchKeys);
};
